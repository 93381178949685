@import 'config/variables';

$vt_disable-pointer-events: true !default;

.#{$ns}image-teaser {
    $root: &;

    &__slide--has-video-teaser {
        #{$root}__picture {
            position: relative;
        }

        // Show text-content and overlays over the video
        // If added on content-wrapper will prevent interaction with video
        #{$root}__content,
        #{$root}__overlay,
        #{$root}__gradient,
        #{$root}__optimizer-container {
            z-index: 1;
        }
    }

    // Render video teaser over the image
    [data-video-teaser] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;

        // Hide video placeholder if video is not rendered
        &:empty {
            display: none;
        }
    }

    // If video does not fit image aspect ratio, it will center itself in the container
    [id^='video-teaser'] {
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @if $vt_disable-pointer-events {
            pointer-events: none;
        }
    }
}
