.cs-product-tile__name{
    height: 100%;
    margin: 0 !important;
    .divTable{
        height: 100%;
        margin: 0;
    }

    .divTableHead {
        height: 50px;
    }
    //wysrodkowanie
    .divTableCell,
    .divTableHead {
        vertical-align: middle;
        text-align: center;
    }
    /////////////////////
    //////////////////////
    //////////////////////
    /////////////////////
    .divTable {
      display: table;
      width: 100%;
    }

    .divTableRow {
      display: table-row;
      font-size: 1.4rem;

      &:nth-child(odd) {
        .divTableCell {
          background-color: #eee;
        }
      }
    }

    .divTableHeading {
      //background-color: #EEE;
      display: table-header-group;
    }

    .divTableCell {
      //border: 1px solid #999999;
        display: table-cell;
       padding: 5px 3px;
    }

    .divTableHead {
      //border: 1px solid #999999;
      display: table-cell;
      padding: 3px 3px;
      background-color: #eee;
      //text-align: center;
      font-weight: 600;
    }

    .divTableHeading {
      background-color: #f00 !important;
    }

    .divTableHeading {
      background-color: #EEE;
      display: table-header-group;
      font-weight: bold;
    }

    .divTableFoot {
      //background-color: #EEE;
      display: table-footer-group;
      font-weight: bold;
    }

    .divTableBody {
      display: table-row-group;
    }
}


.cs-product-tile__details.product-item-details {
    .cs-product-tile__additional.cs-product-tile__additional--list {
            display: none;
    }
}
