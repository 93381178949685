@import 'components/container/container';
@import 'config/variables';
$ns: 'cs-';
$layout_padding: $main-column_padding;

body,
html {
    height: 100%;
    max-width: 100vw;
}

.no-scroll {
    overflow: hidden !important;
    position: fixed;
    width: 100vw;
    height: 100vh;
}

#maincontent {
    flex: 1 0 auto;
    // margin-top: 4rem;
    // margin-bottom: 4rem;
}

.page-wrapper {
    display: block !important;
    min-height: 100%;

    @include media('>=tablet') {
        display: flex !important;
        flex-direction: column !important;
    }
}

.columns .column.main {
    width: auto;
    padding: $layout_padding;
    max-width: 100%;
    min-height: 10rem;
}

.page-layout-1column {
    .product-info-wrapper {
        width: 100%;

        @include media('>=tablet') {
            position: absolute;
            right: 0;
            height: 100%;
            width: 40%;
        }

        @include media('>=laptop') {
            width: 30%;
        }
    }
}

@include media('>=tablet') {
    .page-layout-2columns-left {

        .sidebar-main,
        .sidebar-additional {
            width: 22.5%;
            padding-right: 40px;
            border-right: 1px solid $color_gallery;
        }
    }
}

.ie11 .page-wrapper {
    display: block !important;
}

a {
    color: $link-color_base;

    &:visited {
        color: $link-color_base;
    }

    &:hover {
        color: $link-color_base-hover;
    }
}

.gift-options-cart-item {
    display: none;
}

//Paypal module
.paypal-express-review .page-title-wrapper {
    @include media('>=tablet') {
        padding: 0 1.5rem;
    }
}

.paypal-review.view {
    @include media('<laptop') {
        .#{$ns}cart-item__price {
            padding-left: 0 !important;
        }

        .#{$ns}cart-item__subtotal {
            padding-right: 0 !important;
        }
    }

    @include media('>=tablet') {
        padding: 0 1.5rem;
    }
}

.paypal-express-in-context-mini-cart-container {
    margin-top: 1rem;
}

.paypal-review-discount {
    border-bottom: none;
}

//Amazon Pay login button

//The highest of all amazon pay containers
//Should not be too long
//in minicart, sale block, checkout summary it should be center
.amazon-button-container {
    max-width: 25rem;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    z-index: 303;

    @include media('>=tablet') {
        max-width: 32rem;
    }

    img {
        margin-left: auto;
        margin-right: auto;
    }

    &__cell {

        //Info sign element - is placed absolutely to not take place when button is centered
        &:nth-child(2) {
            position: absolute;
            min-width: 3rem;
            left: calc(100% + 0.6rem);
            top: 1.9rem;

            @include media('<phone') {
                left: calc(100% + 0.8rem);
            }
        }

        //Tooltip style and position
        //Arrow position
        .field-tooltip-content {
            width: 25rem;
            box-sizing: border-box;
            box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.17);
            border: none;
            font-size: 1.3rem;
            padding: 0.8rem;

            @include media('>=phoneLg') {
                width: 28rem;
            }

            &:before {
                border-right-color: $color_gray;
            }

            //Move tooltip from right to top because there is no space in our shops on the right
            .amazon-minicart-container &,
            .#{$ns}cart-summary & {
                top: auto;
                bottom: 42px;
                left: -210px;
                right: auto;

                @include media('>=tablet') {
                    left: -250px;
                }

                @include media('>=laptop') {
                    left: -232px;
                }

                &:before,
                &:after {
                    top: calc(100% + 2rem);
                    left: 210px;
                    transform: rotate(-180deg);

                    @include media('>=tablet') {
                        bottom: -10px;
                        top: 100%;
                        left: 250px;
                        transform: rotate(-90deg);
                    }

                    @include media('>=laptop') {
                        left: 232px;
                    }
                }

                &:before {
                    left: 210px;

                    @include media('<tablet') {
                        border-right-color: transparent;
                    }

                    @include media('>=tablet') {
                        left: 251px;
                        transform: rotate(-90deg);
                    }

                    @include media('>=laptop') {
                        left: 233px;
                    }
                }

                @include media('>=laptop') {
                    &:after {
                        transform: rotate(-90deg);
                    }
                }
            }

            // Cart summary tooltip needs small adjustments
            .#{$ns}cart-summary & {

                &:before,
                &:after {
                    @include media('>=tablet') {
                        top: calc(100% + 2rem);
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}