@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';

.cms-kariera {

  // .cs-container.cs-container--teaser-and-text.cs-image-teaser__content-wrapper,
  // .cs-image-teaser__content-wrapper {
  //   position: absolute !important;
  //   display: grid;
  // }
  .column.main {
    width: auto;
    padding: 0rem 0 0;
    max-width: 100%;
    min-height: 10rem;
  }

  .cs-container.cs-container--button,
  .cs-container.cs-container--headline {
    //padding-top: 3rem;
  }

  .cs-headline {
    //margin-top: 0 !important;
  }

  .cs-container.cs-container--teaser-and-text .cs-image-teaser__content-wrapper {
    position: absolute;
  }

  .cs-container.cs-container--teaser-and-text .cs-image-teaser__wrapper--content-over .cs-image-teaser__content-wrapper {
    padding: 3.76%;
  }

  .cs-image-teaser__slogan {
    font-size: 2.8em;
  }

  .cs-image-teaser__text-content {
    font-size: 12px !important;
  }

  #button {
    margin-top: 40px;
  }
}