@import 'config/variables';
@import 'utils/aspect-ratio';
@import 'vendors/include-media';
$color-tuplex-menu-bg: $color-tuplex-bg-grey;
$navigation_font-size: 1rem !default;
$navigation_overlay-show: false !default; // Box shadow effect applied for the flyout.
$navigation_flyout-box-shadow: 2px 2px 5px rgba($color_background-800, 0.15) !default; // Flyout background color.
$navigation_flyout-background: $color_background-200 !default; // Color of the link.
$navigation_link-color: $color_text-600 !default; // Color of the link in the flyout on hover.
$navigation_link-color-hover: $color_black !default; // Bottom border configuration for flyout category links.
//$navigation_link-color-hover: $color_primary-500 !default; // Bottom border configuration for flyout category links.
$navigation_link-border: 1px solid $color_border-500 !default;
$navigation_lists-mark-parent-on-hover: true !default;

.#{$ns}navigation {
    $root: &;
    box-sizing: border-box;
    font-size: $navigation_font-size;

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;

        &--main {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        &--all-categories {
            width: 100%;
            columns: auto 1;
            column-gap: 0;
            flex: 1 0 auto;
        }

        &--level {
            &_1 {
                background: $navigation_flyout-background;
                width: 100%;
                column-gap: 0;
                flex: 1 0 auto;
                column-count: 1 !important;
            }

            &_2 {
                display: none;
                float: right;
                flex-wrap: wrap;
            }
        }

        &--hidden {
            display: none;

            &+.#{$ns}navigation__extras {
                display: none;
            }
        }

        #{$root}__item--no-children {
            &:nth-child(2) {
                padding-top: 1rem;
            }

            &:last-of-type {
                padding-bottom: 1rem;
            }
        }
    }

    &__item {
        font-size: 0.9em;
        margin: 0;
        width: 100%;

        @if ($navigation_lists-mark-parent-on-hover) {

            &--active,
            &.active {
                &:not([data-category-identifier='all-categories']) {
                    >#{$root}__link {
                        color: $navigation_link-color-hover;

                        .link-title {
                            text-decoration: underline;
                        }
                    }
                }
            }

            &:hover {
                &:not([data-category-identifier='all-categories']) {
                    >#{$root}__link {
                        color: $navigation_link-color-hover;

                        .link-title {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        @else {
            &--main {

                &#{$root}__item--active,
                &.active,
                &:hover {
                    &:not([data-category-identifier='all-categories']) {
                        >#{$root}__link {
                            color: $navigation_link-color-hover;
                        }
                    }
                }
            }
        }

        &--main {
            width: auto;
            font-size: 1.0em;

            @include media('>=laptopLg') {
                font-size: 1.4em;
            }

            &:first-of-type {
                >#{$root}__link {
                    padding-left: 0;
                }
            }

            &:last-of-type {
                >#{$root}__link {
                    padding-right: 0;
                }
            }
        }

        &--all-categories {
            padding: 1rem;
            // Prevents element breaking because of column layout.
            page-break-inside: avoid;
            /* Theoretically FF 20+ */
            break-inside: avoid-column;
            /* IE 11 */
            display: table;
        }

        &--level {
            &_1 {
                padding: 0;
                page-break-inside: avoid;
                break-inside: avoid-column;
                display: flex;
            }
        }

        &--hidden {
            display: none;
        }
    }

    &__link {
        display: block;
        text-transform: uppercase;
        padding: 12px 10px;
        color: $navigation_link-color-hover;
        display: block;
        width: auto;

        &,
        &:visited {
            color: $navigation_link-color-hover;
        }

        &:hover {
            text-decoration: underline !important;
            color: $navigation_link-color-hover;
        }

        &--main {
            font-weight: 700;
        }

        &--all-categories {
            font-weight: 700;
            margin: 0;
            border-bottom: $navigation_link-border;
            padding-bottom: 1.55em;
        }

        &--level {
            &_1 {
                margin: 0;
                padding-bottom: 1.55em;
                text-transform: none;
            }

            &_2 {
                text-transform: none;
                font-size: 1.25em;
            }
        }

        &--with-icon {
            position: relative;
            padding-left: 2.8rem;
        }

        &-icon-wrapper {
            position: absolute;
            width: 2rem;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            max-height: 1.8rem;

            #{$root}__link--category & {
                margin-top: -0.5rem;
            }
        }

        &-products-qty {
            &:before {
                content: '(';
            }

            &:after {
                content: ')';
            }
        }
    }

    #{$root}__item--no-children {
        padding: 0;
    }

    #{$root}__item--no-children>#{$root}__link--level_1 {
        text-transform: none;
        font-size: 1.12em;
        font-weight: 400;
        padding: 0.9em 1.5em;
        border: none;
        color: $navigation_link-color;
    }

    &__flyout {
        width: 100%;
        position: absolute;
        display: none;
        //background: $navigation_flyout-background;
        background: transparent;
        left: 0;
        backface-visibility: hidden;
        z-index: 990; // Make sure flyouts cover gallery nav.
        box-shadow: $navigation_flyout-box-shadow;
        margin-top: 0;
        transform: translate3d(0px, 0px, 0px) !important;
        background-color: $color_white;

        svg {
            pointer-events: none;
        }

        li {
            @supports (-ms-ime-align: auto) {
                list-style: none;
                list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");
                list-style-type: none;
            }
        }
    }

    &__teaser {
        position: relative;
        max-height: 100%;
        padding: 1em 0;
        overflow: hidden;
        margin-bottom: 0 !important;

        &--hidden {
            display: none;
        }

        .#{$ns}image-teaser__figure {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__products {
        padding: 0.5em 1em;
        min-width: 24rem;

        &-header {
            display: block;
            color: $navigation_link-color;
            text-transform: uppercase;
            width: 100%;
            font-weight: 700;
            margin: 0;
            border-bottom: $navigation_link-border;
            padding: 1.1em 1.05em 1.55em;
            font-size: 0.9em;
        }

        &-list {
            margin: 0;
            padding: 0;
            list-style: none;
            height: 100%;
        }
    }

    &__product {
        @include aspect-ratio($width: $tile_aspect-ratio-width, $height: $tile_aspect-ratio-height, $childClass: #{$ns}product-tile, $autoposition: true);
    }

    &__overlay {
        display: none;
        position: absolute;
        z-index: 1000;
        background-color: #000;
        opacity: 0;
        left: 0;
        width: 100%;
        height: 0;
        transform: translate3d(0, 3rem, 0);
        transition: opacity 0.15s linear, transform 0.2s cubic-bezier(0, 0.97, 0.05, 0.99);

        @if $navigation_overlay-show {
            @include media('>=tablet') {
                display: block;
            }
        }

        &--visible {
            opacity: 0.8;
            transform: translate3d(0, 0, 0);
        }
    }

    .page-print & {
        display: none;
    }

    @supports (display: grid) and (grid-auto-flow: row) {
        &__product {
            @include aspect-ratio-reset($childClass: #{$ns}product-tile, $autoposition: true);
        }
    }
}

.#{$ns}container {
    &--navigation {
        display: none;

        @include media('>=laptop') {
            display: block;
            background-color: $color-tuplex-menu-bg-grey;
        }
    }
}

.cs-navigation__link.empty {
    i {
        display: none;
    }
}

.cs-navigation__list.cs-navigation__list--level_3 {
    display: none;
    overflow-y: scroll;
    scrollbar-color: $color-tuplex-menu-bg transparent;

    &::-webkit-scrollbar {
        background-color: transparent;
        width: 8px;
        pointer-events: none !important;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-tuplex-menu-bg;
        transform: scale(2);
        pointer-events: none !important;
    }
}

.cs-navigation__link--level_3 {
    text-transform: none;
}

.cs-navigation__list--level_2 {
    background-color: #efefef;
    position: absolute;
    left: 33.33%;
    min-width: 33.33%;
    top: 0;
    overflow-y: scroll;
    height: 100%;

    .my-title_2,
    .my-title_3 {
        text-align: center;
        font-size: 26px;
        padding: 20px 0;
        font-weight: 700;
    }

    scrollbar-color: $color-tuplex-menu-bg transparent;

    &::-webkit-scrollbar {
        position: absolute;
        background-color: transparent;
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-tuplex-menu-bg;
        transform: scale(2);
    }
}

.cs-navigation__list--level_3 {
    background-color: white;
    position: fixed;
    left: 66.66%;
    max-width: 100%;
    min-width: 33.33%;
    top: 0;
    height: 100%;
}

.cs-navigation__list--level_1 {
    width: 33.33% !important;
    flex-direction: column;
    display: flex;
    overflow-y: inherit;
    overflow-x: hidden;
    height: 440px;
    scrollbar-color: $color-tuplex-menu-bg transparent;

    &::-webkit-scrollbar {
        position: absolute;
        background-color: transparent;
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-tuplex-menu-bg;
        transform: scale(2);
    }
}

.cs-navigation__products.cs-navigation__extras {
    display: block;
    padding: 0;
    position: absolute;
    max-width: 25%;
    width: 25%;
    min-width: 25%;
    height: 100%;
    top: 0;
    left: 24%;
    background: white;
}

.cs-product-tile--grid.cs-product-tile--static {
    .cs-product-tile__name-link {
        display: none;
    }
}

.cs-navigation__link {
    border-bottom: 1px solid transparent;
    display: inline-flex;
    justify-content: space-between;
    padding: 12px;
}

.cs-navigation__link--level_1,
.cs-navigation__link--level_2,
.cs-navigation__link--level_3 {
    font-size: 1.5rem;
    pointer-events: none;
    padding: 6px 12px;
}

.cs-navigation__link.empty {
    pointer-events: all;
}

.cs-navigation__item {
    .fa-chevron-right {
        display: inline-flex;
        align-items: center;
    }
}

.cs-navigation__item:hover {
    background-color: $color-tuplex-menu-bg;
}

.cs-navigation__link.cs-navigation__link--main {
    cursor: pointer;
}

.cs-navigation__item.cs-navigation__item--level_3 {
    .cs-navigation__link.cs-navigation__link--level_3 {
        width: 100%;
    }
}

.cs-navigation__item--with-flyout>.cs-navigation__link {
    &:after {
        content: "\f078";
        font-family: "Font Awesome 5 Pro";
        display: inline-flex;
        align-items: center;
        height: 100%;
        font-weight: 600;
        margin-left: 5px;
        text-decoration: none !important;
    }
}

.underline {
    text-decoration: underline;
}

.cs-navigation__item.cs-navigation__item--main.cs-navigation__item--with-flyout:hover {
    text-decoration: none !important;
}

.active-category {
    background-color: $color-tuplex-menu-bg;
}