/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.cs-slider-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  min-height: 3rem; }
  .cs-slider-pagination--fraction {
    gap: 0;
    white-space: pre-wrap;
    color: inherit;
    font-weight: 600; }
    .cs-slider-pagination--fraction span {
      color: #FFEF45; }
  .cs-slider-pagination__bullet {
    position: relative;
    border-radius: 2px;
    background: #f0f0f0;
    border: 0;
    inline-size: 2.2rem;
    block-size: 0.9rem;
    transition: all 0.15s;
    overflow: hidden;
    text-indent: -99rem; }
    .cs-slider-pagination__bullet.current {
      background-color: #FFEF45; }
    .cs-slider-pagination__bullet:only-child {
      display: none; }
