/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/* Radio and checkbox styling */
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
.cs-qty-increment {
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  .cs-qty-increment--wishlist {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ }
  .cs-qty-increment__label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ }
  .cs-qty-increment__input {
    font-size: 1em;
    line-height: 1.275em;
    padding: 0.75em 1em;
    color: #484848;
    background: #fff;
    border-radius: 0px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    transition: border-color 0.5s;
    margin: 0 0 0.2em;
    width: 100%;
    background-clip: padding-box;
    font-family: inherit;
    font-size: inherit;
    text-align: center;
    margin: 0;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-radius: 0;
    width: 0;
    min-width: 2ch;
    flex: 1;
    order: 2; }
    .cs-qty-increment__input:hover:not([disabled]), .cs-qty-increment__input:focus:not([disabled]) {
      border: 1px solid #78b0e0;
      box-shadow: none; }
    .cs-qty-increment__input::placeholder {
      opacity: 1;
      color: #888; }
    .cs-qty-increment__input[disabled] {
      opacity: 0.65; }
    .cs-qty-increment__input, .cs-qty-increment__input:hover:not([disabled]), .cs-qty-increment__input:focus:not([disabled]) {
      border-left-width: 0;
      border-right-width: 0;
      border-color: #dcdcdc; }
    .cs-qty-increment__input.mage-error {
      border-color: #e41f12 !important; }
      .cs-qty-increment__input.mage-error ~ .cs-qty-increment__button {
        border-color: #e41f12 !important; }
    .cs-qty-increment__input + .mage-error {
      order: 3;
      width: 100%; }
  .cs-qty-increment__button {
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s;
    max-width: 3rem;
    background-color: transparent;
    flex: 1 1 2rem;
    position: relative;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-radius: 0px; }
    .cs-qty-increment__button, .cs-qty-increment__button:visited, .cs-qty-increment__button:focus {
      background-color: transparent;
      border: 1px solid #dcdcdc; }
      .cs-qty-increment__button *, .cs-qty-increment__button:visited *, .cs-qty-increment__button:focus * {
        fill: #78b0e0; }
    .cs-qty-increment__button:hover, .cs-qty-increment__button:active {
      background-color: #78b0e0;
      border: 1px solid #78b0e0;
      cursor: pointer; }
      .cs-qty-increment__button:hover *, .cs-qty-increment__button:active * {
        fill: #fff; }
    .cs-qty-increment__button--decrement {
      order: 1;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .cs-qty-increment__button--increment {
      order: 2;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .cs-qty-increment__button--disabled {
      opacity: 1; }
      .cs-qty-increment__button--disabled, .cs-qty-increment__button--disabled:hover, .cs-qty-increment__button--disabled:visited, .cs-qty-increment__button--disabled:focus {
        background-color: transparent;
        border: 1px solid #dcdcdc;
        cursor: not-allowed; }
        .cs-qty-increment__button--disabled *, .cs-qty-increment__button--disabled:hover *, .cs-qty-increment__button--disabled:visited *, .cs-qty-increment__button--disabled:focus * {
          fill: #dcdcdc; }
  .cs-qty-increment__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .cs-qty-increment__icon--decrement {
      width: 1.2rem;
      height: 0.3rem; }
    .cs-qty-increment__icon--increment {
      width: 1.2rem;
      height: 1.2rem; }
