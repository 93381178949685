/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.cs-container--image-teaser .cs-image-teaser--items-in-row-2 .cs-image-teaser__slides {
  gap: 2rem !important; }

.cs-container--image-teaser .cs-image-teaser--items-in-row-2 .cs-image-teaser__slides > .cs-image-teaser__slide {
  width: 100% !important;
  margin-bottom: 0 !important; }
