/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
@media (min-width: 48.01em) {
  .cs-aftersearch-nav__filters .cs-aftersearch-nav__filter.filter-attribute-kolor .cs-aftersearch-nav__filter-title {
    display: none !important; } }

.cs-aftersearch-nav__filters .cs-aftersearch-nav__filter.filter-attribute-kolor .cs-aftersearch-nav__filter-content .swatch-attribute.swatch-layered.kolor {
  padding: 1rem 0rem; }
  .cs-aftersearch-nav__filters .cs-aftersearch-nav__filter.filter-attribute-kolor .cs-aftersearch-nav__filter-content .swatch-attribute.swatch-layered.kolor .swatch-attribute-options {
    justify-content: flex-start; }
    .cs-aftersearch-nav__filters .cs-aftersearch-nav__filter.filter-attribute-kolor .cs-aftersearch-nav__filter-content .swatch-attribute.swatch-layered.kolor .swatch-attribute-options .swatch-option-link-layered {
      min-width: 10rem; }
      .cs-aftersearch-nav__filters .cs-aftersearch-nav__filter.filter-attribute-kolor .cs-aftersearch-nav__filter-content .swatch-attribute.swatch-layered.kolor .swatch-attribute-options .swatch-option-link-layered .swatch-option.color {
        border: none; }
        .cs-aftersearch-nav__filters .cs-aftersearch-nav__filter.filter-attribute-kolor .cs-aftersearch-nav__filter-content .swatch-attribute.swatch-layered.kolor .swatch-attribute-options .swatch-option-link-layered .swatch-option.color::after {
          display: none; }
        .cs-aftersearch-nav__filters .cs-aftersearch-nav__filter.filter-attribute-kolor .cs-aftersearch-nav__filter-content .swatch-attribute.swatch-layered.kolor .swatch-attribute-options .swatch-option-link-layered .swatch-option.color::before {
          display: none; }
