.popup-redingo{
  button{
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 250px;
    background: #78b0e0;
    justify-content: space-around;
    font-weight: 700;
    font-size: 1em;
    color:white;
    cursor: pointer;
    i:before{
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
      content: "\f303";
      color: white;
      cursor: pointer;
    }
  }
  .content-wrapper{
    .modal-header{
      .cs-container{
        cursor:default;
      }
    }
    button{
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
      border: none;
      background: transparent;
    }
    button:before{
      font-family: "Font Awesome 5 Pro";
      font-weight: 400;
      content: "\f00d";
      color: black;
      font-size: 2em;
      padding: 0.5rem 1rem;
    }
  }
  .popup-form-button{
    background: #78b0e0;
    color: white;
    border: none;
    padding: 1rem;
    cursor: pointer;
  }
}
