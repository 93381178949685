/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.countdown #cd_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #80cad4;
  color: white;
  font-size: 2rem;
  text-transform: uppercase;
  padding: 0 1rem;
  min-height: 65px; }
  .countdown #cd_text .dynamicCountdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem; }
    .countdown #cd_text .dynamicCountdown .days,
    .countdown #cd_text .dynamicCountdown .hours,
    .countdown #cd_text .dynamicCountdown .minutes,
    .countdown #cd_text .dynamicCountdown .seconds {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .countdown #cd_text .dynamicCountdown .liczby {
      margin: 0rem 0.5rem; }
      .countdown #cd_text .dynamicCountdown .liczby span {
        background-color: white;
        color: #484848;
        margin: 0.2rem;
        padding: 0rem 0.5rem; }
    .countdown #cd_text .dynamicCountdown .nazwa {
      font-size: 1.2rem; }
    .countdown #cd_text .dynamicCountdown .countdown-separator {
      color: white;
      padding-bottom: 15px; }
