.customer-account-create, .customer-account-login {
  .cs-headline {
      margin: 0rem 0 1rem 0 !important;
  }
  .cs-dashboard .fieldset {

      margin: auto !important;
      max-width: 85rem !important;
  }
  .cs-dashboard .legend {
    padding: 3rem 0 1rem 0 !important;
  }

.legend {
  margin: 1rem 0;
}
  .label {
    margin: 1rem 0;
  }
}

.zgody-checkbox {
  margin: 0 0 0 1rem;
}
