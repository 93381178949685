@import 'config/variables';
@import 'components/price-box/mixin';
@import 'components/price-box/variables.scss';
@import 'utils/visually-hidden';

// stylelint-disable no-descending-specificity

$configurable-products-show-from-price-label: false;

.price-box {
    $root: &;
    @include priceBox();

    .price {
        color: $price-box_normal-price-color;
        font-size: $price-box_normal-price-font-size;
        font-weight: $price-box_normal-price-font-weight;
        margin: 0;

        &-label {
            color: $price-box_label-color;
            display: none;
            font-size: $price-box_label-font-size;
            font-weight: $price-box_label-font-weight;
            text-transform: uppercase;
            margin: 0 $price-box_label-margin $price-box_label-margin 0;

            @if ($price-box_label-display-colon) {
                &:after {
                    content: ':';
                }
            }

            body:not(.page-product-bundle, .page-product-grouped)
                p:not(.price-from, .price-to)
                & {
                @include visually-hidden();
            }
        }
    }

    @if $configurable-products-show-from-price-label {
        .normal-price {
            .price-label {
                display: inline;
            }
        }
    }

    .price-wrapper {
        display: flex;
    }

    .price-from,
    .price-to {
        display: inline-block;
        margin: 0;
        padding: 0;

        > .price-container {
            font-size: $price-box_label-font-size;
        }

        .price-label {
            display: inline-block;
            padding: 0;
        }
    }

    .old-price {
        .price {
            text-decoration: $price-box_old-price-text-decoration;
            color: $price-box_old-price-color;
            font-size: $price-box_old-price-font-size;
            font-weight: $price-box_old-price-font-weight;
        }
    }

    .special-price {
        .price {
            padding-left: 0;
            color: $price-box_special-price-color;
            font-size: $price-box_special-price-font-size;
            font-weight: $price-box_special-price-font-weight;
        }

        &.active {
            .price {
                color: $price-box_special-price-color;
            }
        }
    }

    .price-from {
        margin-right: $price-box_space-between-prices;
    }

    .#{$ns}price--pdp_dailydeal-countdown & {
        &:not(.price-final_price_without_daily_deal) {
            display: none;
        }
    }

    .price-container.price-final_price {
        &:not(:only-child) .price {
            color: $price-box_special-price-color;
        }
    }
}

.#{$ns}bundle-box__add {
    .price-as-configured {
        margin: 1rem 0 0;
    }
}

.minimal-price-link {
    margin-top: 0 !important;
    margin-right: 1.5rem;
    position: relative;

    &:after {
        color: $price-box_normal-price-color;
        display: block;
        content: '-';
        right: -1rem;
        top: 0;
        position: absolute;
    }
}
