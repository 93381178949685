/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.page-layout-landing-category .cs-page-category {
  padding-top: 0; }

.page-layout-landing-category .category-view {
  position: relative; }

.page-layout-landing-category .tuplex-lending-top {
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1; }
  .page-layout-landing-category .tuplex-lending-top .page-title__headline.cs-page-title__headline {
    color: #fff; }
  .page-layout-landing-category .tuplex-lending-top .cs-cms-block.cs-page-category__description h2 {
    color: #fff; }

.page-layout-landing-category .category-image {
  margin: 0; }
  .page-layout-landing-category .category-image .image {
    filter: brightness(0.6);
    width: 100vw; }
