@import 'config/colors';
@import 'vendors/include-media';

.countdown #cd_text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #80cad4;
    color: white;
    font-size: 2rem;
    text-transform: uppercase;
    padding: 0 1rem;
    min-height: 65px;

    .dynamicCountdown {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 1rem;

        .days,
        .hours,
        .minutes,
        .seconds {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .liczby {
            margin: 0rem 0.5rem;

            span {
                background-color: white;
                color: #484848;
                margin: 0.2rem;
                padding: 0rem 0.5rem;
            }
        }

        .nazwa {
            font-size: 1.2rem;
        }

        .countdown-separator {
            color: white;
            padding-bottom: 15px;
        }
    }
}