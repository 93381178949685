@charset "UTF-8";
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.category-view {
  max-width: 130rem;
  margin: 0 auto; }
  .category-view .page-title-wrapper {
    margin: 0 0 2rem;
    position: relative; }
    .category-view .page-title-wrapper:before {
      content: " ";
      display: block;
      width: 97%;
      height: 1px;
      background-color: #dcdcdc;
      position: relative;
      top: 20px;
      margin: 0 auto;
      z-index: -1; }
      @media (min-width: 1300px) {
        .category-view .page-title-wrapper:before {
          width: 100%; } }

.page-title {
  font-family: "Lato", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2.6rem;
  line-height: 1.1;
  color: #484848;
  font-weight: 700;
  text-transform: uppercase;
  margin: 1rem 0 2rem;
  padding-bottom: 1rem;
  text-transform: none;
  font-weight: 700;
  margin: 0 auto;
  max-width: 130rem;
  display: table;
  text-align: left;
  position: relative;
  background-color: #fff;
  z-index: 1;
  padding: 0 22px;
  font-size: 36px;
  color: #484848; }
  .page-title__headline {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    display: inline; }
    .page-title__headline .base {
      text-transform: initial; }
  .page-title__span {
    color: #696969;
    font-weight: 300;
    padding: 0; }

.amlocator-location-view .page-title-wrapper {
  display: none; }

.amlocator-index-index .page-title-wrapper {
  padding-top: 4rem;
  margin: 0 0 2rem;
  position: relative; }
  .amlocator-index-index .page-title-wrapper:before {
    content: " ";
    display: block;
    width: 97%;
    height: 1px;
    background-color: #dcdcdc;
    position: relative;
    top: 20px;
    margin: 0 auto;
    z-index: -1; }
    @media (min-width: 1300px) {
      .amlocator-index-index .page-title-wrapper:before {
        width: 100%; } }

.page-products.catalog-category-view .cs-product-tile__addons,
.page-products.catalogsearch-result-index .cs-product-tile__addons {
  display: none; }

.products.list.items.product-items.cs-products-list__items .cs-product-tile__name-link {
  display: flex !important;
  justify-content: space-between; }
  .products.list.items.product-items.cs-products-list__items .cs-product-tile__name-link:before {
    display: none;
    content: "Zobacz więcej";
    min-width: 120px;
    height: 33px;
    font-weight: 400;
    align-items: center;
    justify-content: center;
    right: 33px;
    padding: 0 10px;
    font-size: 15px;
    border: 1px solid #e41f12;
    color: #e41f12;
    order: 4;
    margin-right: 30px; }
    @media (min-width: 48em) {
      .products.list.items.product-items.cs-products-list__items .cs-product-tile__name-link:before {
        display: flex; } }
  .products.list.items.product-items.cs-products-list__items .cs-product-tile__name-link:after {
    content: "\f105";
    font-family: "Font Awesome 5 Pro";
    width: 33px;
    height: 33px;
    font-weight: 400;
    display: block;
    position: absolute;
    right: 0;
    text-align: center;
    background: #e41f12;
    color: #fff; }
