/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
body {
  overflow-x: hidden; }

.promo {
  text-decoration: line-through; }

.header.ecommerce-tuplex.links.cs-header__user-nav {
  display: flex; }

.info_product_card {
  border: 1px solid; }

.categorypath-katalog-produktow-poliweglan-poliweglan-kanalikowy-komorowy-bezbarwny .col:nth-child(5),
.categorypath-katalog-produktow-poliweglan-poliweglan-kanalikowy-komorowy-brazowy .col:nth-child(5),
.categorypath-katalog-produktow-poliweglan-poliweglan-kanalikowy-komorowy-opal .col:nth-child(5) {
  width: 200px; }

.map-text-home .cs-image-teaser__cta-button-span:before {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-size: 22px;
  position: absolute;
  right: 17px;
  font-weight: 400; }

.category-kim-jestesmy .cs-button-component__amount {
  display: none; }

.price-final_price .minimal-price {
  display: block; }

.amasty_distance {
  margin-left: 18px; }

.belka-podane-ceny {
  padding: 10px;
  background: #FFEF45;
  font-size: 18px; }
  .belka-podane-ceny a {
    color: #346097;
    padding: 0px; }
  .belka-podane-ceny .read-more a {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900; }
    .belka-podane-ceny .read-more a::after {
      content: "\f105";
      font-family: "Font Awesome 5 Pro";
      width: 33px;
      height: 33px;
      font-weight: 400;
      margin-left: 10px;
      color: #346097; }
  .belka-podane-ceny .text {
    max-width: 130rem;
    text-align: center;
    padding: 5px 0px;
    color: #000;
    margin: auto; }
  .belka-podane-ceny .pozostale {
    max-width: 130rem;
    text-align: center;
    padding: 5px 0px;
    color: #000;
    margin: auto; }
  .belka-podane-ceny .read-more {
    max-width: 130rem;
    padding: 5px 0px;
    margin: auto;
    text-align: center; }

.cs-image-teaser__carousel .cs-image-teaser__slides picture {
  position: relative; }

.swatch-attribute.swatch-layered.kolor .swatch-attribute-options .swatch-option-link-layered .swatch-option.color, .swatch-attribute.swatch-layered.kolor .swatch-attribute-options .swatch-option-link-layered .swatch-option.image {
  box-shadow: 0px 0px 4px -2px #000; }

.swatch-attribute.swatch-layered.kolor .swatch-attribute-options .swatch-option-link-layered span {
  display: flex;
  justify-content: center;
  color: #484848;
  padding: 3px 0px 10px 0px; }

.cs-category-links__list-wrapper .cs-category-links__list .cs-category-links__list-item .cs-category-links__link span {
  display: none !important; }

@media (min-width: 90em) {
  .checkout-cart-index .cs-cart-page.cs-container__inner .cart.table-wrapper.cs-cart-table-wrapper .cs-cart-table__head tr th:first-child {
    width: 43%; } }
