
.categorypath-kim-jestesmy {
  .cs-image-teaser.cs-image-teaser--items-in-row-2.cs-image-teaser--items-in-row-mobile-1 {
    .cs-image-teaser__slide.cs-image-teaser__slide--scheme-dark.cs-image-teaser__slide--image-uploaded,
    .cs-image-teaser__slide.cs-image-teaser__slide--scheme-light.cs-image-teaser__slide--image-uploaded {
      padding: 0;
      width: 50% !important;
      margin-bottom: 0rem !important;

      &:nth-child(odd) {
        margin-right: auto;
      }
    }
  }
}
