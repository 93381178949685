@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';


//page-layout-strefa-wiedzy


 .page-layout-eco {

   h1,h2 {
     color: $color-eco-tuplex;
     text-align: center;
     font-size: 4rem;
   }



    h3:not(.cs-category-links__title):not(.cs-footer__section-title):not(.footer-tuplex):not(.newsletter-title) {
      margin: 3rem 0 3rem;
      font-size: 1.8rem;
      &:before {
        content: "\f06c";
        color: $color-eco-tuplex;
        font-family: "Font Awesome 5 Pro";
        //font-weight: 900;
        display: inline-block;
        margin-left: 0em;
        width: 50px;
        height: 50px;
        font-size: 24px;
        padding: 12px 10px;
        background: whitesmoke;
        border-radius: 50%;
        margin-right: 20px;
      }



    }


    font-size: 1.6rem;

    p {
      text-align: justify;
      img {
        margin-top: 20px;
        width: 100%;
      }
    }


    #button {
      margin-top: 2rem;
    }


    .cs-button-component__span {

      min-width: 250px;
      font-size: 20px;
    }

    .cs-paragraph {

      img {
        width: 100%;
      }

      @include media('>=tablet') {
      margin: 0 0 0rem;
      }

      ul {
        margin-top: 2rem;
        list-style: none;
        padding: 3rem 3rem 3rem 6rem;
        background-color: whitesmoke;
      }

      ul > li:before {
        content: "\f00c";
        color: $color-eco-tuplex;
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        display: inline-block;
        margin-left: -1.9em;
        width: 1.9em;
      }
    }


    .column.main {
        padding: 0rem 0 0;
    }


    .cs-container {
      padding-left: 0rem;
      padding-right: 0rem;
  }


  }


/////
cs-category-links__dropdown cs-category-links-dropdown




// .cs-category-links__list {
//
//   &:visited {
//       color: red;
//   }
//
//   font-size: 30px !important;
//
//   ul {
//     margin-top: 2rem;
//     list-style: none;
//     padding: 3rem 3rem 3rem 6rem;
//     background-color: $color_gallery;
//   }
//
//   ul > li:before {
//     content: "\f00c";
//     color: $color-tuplex-green;
//     font-family: "Font Awesome 5 Pro";
//     font-weight: 900;
//     display: inline-block;
//     margin-left: -1.9em;
//     width: 1.9em;
//   }
// }

.cs-category-links__list a {
  font-size: 30px !important
}

.cs-category-links__list-all {
    display: none;
}


.cs-category-links__list-item {
    display: inline-block;
    margin: 1rem 0 0 2.5rem;
}


.category-strefa-wiedzy {
  h2.cs-category-links__title {
    display: none;
  }
}

.elewacje-wentylowane, .strefa-wiedzy-szachownica {
  p:first-child {
    padding-top: 150px;

      @include media('<laptop') {
        padding-top: 50px;
      }
  }
}



.col-md-4, .col-md-9 {
  padding: 1rem;
}
.col-lg-3, .col-lg-6 {
  padding: 2rem;
}
