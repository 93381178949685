/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Mixins for flexible containers declaration.
 */
/**
 * Defines page container. This mixin makes sure that the content has always equal padding
 * for mobile screens on both size of the device.
 */
/**
 * Negates parent container's padding and sets it own.
 * Somethimes this has to be done to make background even to other containers' content.
 */
/**
 * Defines container wrapper that makes sure contnet doesn't extend above certain width and is always centered.
 */
.cs-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box; }
  .cs-container__inner {
    max-width: 130rem;
    width: 100%;
    margin: 0 auto;
    position: relative; }
    .cs-container__inner:before, .cs-container__inner:after {
      content: '';
      display: table;
      clear: both; }
  .cs-container__aside {
    width: 100%; }
    @media (min-width: 48em) {
      .cs-container__aside {
        width: 40%; } }
    @media (min-width: 64em) {
      .cs-container__aside {
        width: 32%; } }
    @media (min-width: 90em) {
      .cs-container__aside {
        width: 30%; } }
  .cs-container__main-column {
    width: 56%;
    position: relative; }
    @media (min-width: 64em) {
      .cs-container__main-column {
        width: 65%; } }
    @media (min-width: 90em) {
      .cs-container__main-column {
        width: 67%; } }
    @media (max-width: 47.99em) {
      .cs-container__main-column {
        max-width: 100% !important;
        width: 100% !important; } }
    @media (min-width: 64em) {
      .cs-container__main-column--space-top {
        padding-top: 3rem; } }
  .cs-container--contrast {
    background-color: #f0f0f0; }
    @media (min-width: 64em) {
      .cs-container--contrast {
        background-image: url("../images/background.png");
        background-size: cover;
        background-repeat: no-repeat; } }
  .cs-container--spacious {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media (min-width: 64em) {
      .cs-container--spacious {
        padding-top: 3rem;
        padding-bottom: 3rem; } }
  .cs-container--noscroll {
    overflow: hidden; }
  .cs-container--ontop {
    z-index: 301; }
  .cs-container--viewport-width {
    max-width: none; }

/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
body,
html {
  height: 100%;
  max-width: 100vw; }

.no-scroll {
  overflow: hidden !important;
  position: fixed;
  width: 100vw;
  height: 100vh; }

#maincontent {
  flex: 1 0 auto; }

.page-wrapper {
  display: block !important;
  min-height: 100%; }
  @media (min-width: 48em) {
    .page-wrapper {
      display: flex !important;
      flex-direction: column !important; } }

.columns .column.main {
  width: auto;
  padding: 4rem 0 0;
  max-width: 100%;
  min-height: 10rem; }

.page-layout-1column .product-info-wrapper {
  width: 100%; }
  @media (min-width: 48em) {
    .page-layout-1column .product-info-wrapper {
      position: absolute;
      right: 0;
      height: 100%;
      width: 40%; } }
  @media (min-width: 64em) {
    .page-layout-1column .product-info-wrapper {
      width: 30%; } }

@media (min-width: 48em) {
  .page-layout-2columns-left .sidebar-main,
  .page-layout-2columns-left .sidebar-additional {
    width: 22.5%;
    padding-right: 40px;
    border-right: 1px solid #f0f0f0; } }

.ie11 .page-wrapper {
  display: block !important; }

a {
  color: #78b0e0; }
  a:visited {
    color: #78b0e0; }
  a:hover {
    color: #346097; }

.gift-options-cart-item {
  display: none; }

@media (min-width: 48em) {
  .paypal-express-review .page-title-wrapper {
    padding: 0 1.5rem; } }

@media (max-width: 63.99em) {
  .paypal-review.view .cs-cart-item__price {
    padding-left: 0 !important; }
  .paypal-review.view .cs-cart-item__subtotal {
    padding-right: 0 !important; } }

@media (min-width: 48em) {
  .paypal-review.view {
    padding: 0 1.5rem; } }

.paypal-express-in-context-mini-cart-container {
  margin-top: 1rem; }

.paypal-review-discount {
  border-bottom: none; }

.amazon-button-container {
  max-width: 25rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  z-index: 303; }
  @media (min-width: 48em) {
    .amazon-button-container {
      max-width: 32rem; } }
  .amazon-button-container img {
    margin-left: auto;
    margin-right: auto; }
  .amazon-button-container__cell:nth-child(2) {
    position: absolute;
    min-width: 3rem;
    left: calc(100% + 0.6rem);
    top: 1.9rem; }
    @media (max-width: 23.115em) {
      .amazon-button-container__cell:nth-child(2) {
        left: calc(100% + 0.8rem); } }
  .amazon-button-container__cell .field-tooltip-content {
    width: 25rem;
    box-sizing: border-box;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.17);
    border: none;
    font-size: 1.3rem;
    padding: 0.8rem; }
    @media (min-width: 30em) {
      .amazon-button-container__cell .field-tooltip-content {
        width: 28rem; } }
    .amazon-button-container__cell .field-tooltip-content:before {
      border-right-color: #888; }
    .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content,
    .cs-cart-summary .amazon-button-container__cell .field-tooltip-content {
      top: auto;
      bottom: 42px;
      left: -210px;
      right: auto; }
      @media (min-width: 48em) {
        .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content,
        .cs-cart-summary .amazon-button-container__cell .field-tooltip-content {
          left: -250px; } }
      @media (min-width: 64em) {
        .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content,
        .cs-cart-summary .amazon-button-container__cell .field-tooltip-content {
          left: -232px; } }
      .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before, .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:after,
      .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before,
      .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:after {
        top: calc(100% + 2rem);
        left: 210px;
        transform: rotate(-180deg); }
        @media (min-width: 48em) {
          .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before, .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:after,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:after {
            bottom: -10px;
            top: 100%;
            left: 250px;
            transform: rotate(-90deg); } }
        @media (min-width: 64em) {
          .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before, .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:after,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:after {
            left: 232px; } }
      .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before,
      .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before {
        left: 210px; }
        @media (max-width: 47.99em) {
          .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before {
            border-right-color: transparent; } }
        @media (min-width: 48em) {
          .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before {
            left: 251px;
            transform: rotate(-90deg); } }
        @media (min-width: 64em) {
          .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:before,
          .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before {
            left: 233px; } }
      @media (min-width: 64em) {
        .amazon-minicart-container .amazon-button-container__cell .field-tooltip-content:after,
        .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:after {
          transform: rotate(-90deg); } }
    @media (min-width: 48em) {
      .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:before, .cs-cart-summary .amazon-button-container__cell .field-tooltip-content:after {
        top: calc(100% + 2rem);
        transform: rotate(180deg); } }
