@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';


//////
.tuplex-table {
    margin: 2rem auto;
    width: 100%;

    @include media('>=laptop') {
        width: 75%;
    }

    th,
    td {
        width: 33.333%;
    }

    thead {
        background-color: $color-tuplex-blue;
        color: $color-white;

        th:not(:last-child) {
            border-right: 2px solid $color-white;
        }
    }

    tbody {
        tr:nth-child(even) {
            td {
                background-color: $color_gallery;
            }
        }

        td:not(:last-child) {
            border-right: 2px solid $color-white;
        }
    }
}

.table-wrapper.grouped {
    .price-and-cart {
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        button {
            margin: 15px 0;
        }
    }
}

.table-wrapper.grouped {
    .rows.tier-price.summary.qty>.col.qty {
        position: relative;
    }

    .qty.summary-wrapper-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: white;
    }
}