/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.page-layout-meshqa {
  font-size: 1.6rem; }
  .page-layout-meshqa h1, .page-layout-meshqa h2 {
    color: #354247;
    text-align: center;
    font-size: 4rem; }
  .page-layout-meshqa h3:not(.cs-category-links__title):not(.cs-footer__section-title):not(.footer-tuplex):not(.newsletter-title) {
    margin: 3rem 0 3rem;
    font-size: 1.8rem;
    display: flex; }
    .page-layout-meshqa h3:not(.cs-category-links__title):not(.cs-footer__section-title):not(.footer-tuplex):not(.newsletter-title):before {
      content: url("../images/Meshqa-Icon.svg");
      display: inline-block;
      margin-left: 0em;
      width: 50px;
      height: 50px;
      font-size: 24px;
      padding: 0;
      background: whitesmoke;
      border-radius: 50%;
      margin-right: 20px; }
  .page-layout-meshqa p {
    text-align: justify; }
    .page-layout-meshqa p img {
      margin-top: 20px;
      width: 100%; }
  .page-layout-meshqa #button {
    margin-top: 2rem; }
  .page-layout-meshqa .cs-button-component__span {
    min-width: 250px;
    font-size: 20px; }
  .page-layout-meshqa .cs-paragraph img {
    width: 100%; }
  @media (min-width: 48em) {
    .page-layout-meshqa .cs-paragraph {
      margin: 0 0 0rem; } }
  .page-layout-meshqa .cs-paragraph ul {
    margin-top: 2rem;
    list-style: none;
    padding: 3rem 3rem 3rem 6rem;
    background-color: whitesmoke; }
  .page-layout-meshqa .cs-paragraph ul > li:before {
    content: "\21";
    color: #354247;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    display: inline-block;
    margin-left: -1.9em;
    width: 1.9em; }
  .page-layout-meshqa .column.main {
    padding: 0rem 0 0; }
  .page-layout-meshqa .cs-container {
    padding-left: 0rem;
    padding-right: 0rem; }

cs-category-links__dropdown cs-category-links-dropdown
.cs-category-links__list a {
  font-size: 30px !important; }

.cs-category-links__list-all {
  display: none; }

.cs-category-links__list-item {
  display: inline-block;
  margin: 1rem 0 0 2.5rem; }

.category-strefa-wiedzy h2.cs-category-links__title {
  display: none; }

.elewacje-wentylowane p:first-child, .strefa-wiedzy-szachownica p:first-child {
  padding-top: 150px; }
  @media (max-width: 63.99em) {
    .elewacje-wentylowane p:first-child, .strefa-wiedzy-szachownica p:first-child {
      padding-top: 50px; } }

.col-md-4, .col-md-9 {
  padding: 1rem; }

.col-lg-3, .col-lg-6 {
  padding: 2rem; }
