/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
.promo-infobanner {
  text-align: center;
  background: #cc1c10;
  padding: 10px;
  margin: 0 0 10px; }
  .promo-infobanner a {
    color: white;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 300; }
    .promo-infobanner a .value-promobanner_header {
      font-weight: 700 !important; }
    .promo-infobanner a .sprawdz-promobanner_header {
      font-weight: 400 !important;
      background: white;
      color: #cc1c10;
      padding: 0 10px;
      margin-left: 10px;
      border-radius: 5px; }
    .promo-infobanner a:hover {
      color: white; }

.promo-infobanner-green {
  text-align: center;
  background: #83bd34;
  padding: 10px;
  margin: 0 0 10px; }
  .promo-infobanner-green a {
    color: white;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 300; }
    @media (max-width: 47.99em) {
      .promo-infobanner-green a {
        font-size: 20px; } }
    .promo-infobanner-green a .value-promobanner_header {
      font-weight: 700 !important; }
    .promo-infobanner-green a .sprawdz-promobanner_header {
      font-weight: 400 !important;
      background: white;
      color: #83bd34;
      padding: 0 10px;
      margin-left: 10px;
      border-radius: 5px; }
    .promo-infobanner-green a:hover {
      color: white; }

@media (max-width: 47.99em) {
  .header__banner_promobanner {
    margin-top: 0px; } }
