@import 'config/variables';
@import 'vendors/include-media';

.block.discount.cs-discount.cs-discount--coupon {
    .content.cs-discount__content {
        display: block !important;
    }

    #discount-coupon-form {
        .field>.label {
            color: red;
            font-size: 1.7rem;
        }

        .control>.input-text {
            border: 2px solid black;
        }

        .actions-toolbar.cs-discount__buttons>button {
            border: 2px solid #77b1e0;
        }
    }
}