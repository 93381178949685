#tuplex-webinar-counter {
  font-size: 51px;
  font-weight: 700;
  padding: 20px;
  background: #cc1c12;
  color: white;
  width: 504px;
  text-align: center;
  margin: auto;
}
