@import 'config/colors';

.checkout-cart-index {
  .page-title {
    padding: 2rem 0;
  }

  #shipping-zip-form {
    display: none;
  }

  .field.choice.item {
    display: block;
  }
}


.page-layout-checkout {

  .shipping {
    .excl {
      span {
        font-size: 40px !important;
      }
    }
  }

  .checkout-agreements {
    margin-top: 10px;
  }



  .totals-tax {
    display: none;
  }

  .field-tooltip-content {
    background: $color-tuplex-yellow !important;
    padding: 1rem !important;
    color: $color_black !important;
    margin: 1rem 0 !important;
  }
}

.checkout-index-index {
  .cs-checkout {
    .cs-checkout__sidebar {
      .checkout-agreements {
        .checkout-agreement {
          display: block !important;
        }
      }
    }
  }
}

.order-attributes>.order-attributes-form.fieldset.address {
  select.select {
    width: 100% !important;
  }
}


// p24 checkout

.payment-method-przelewy24__agreements.fieldset>.payment-method-przelewy24__agreement.field.choice {
  display: block;
}

// error shipingmethods list

.row.row-error>.col.col-error>.no-display {
  display: none;
}