@import 'config/variables';
@import 'vendors/include-media';
@import 'components/container/mixin';
@import 'utils/deep-get/deep-get.scss';

$header_font-size: 1rem !default;
$header_border-bottom: 1px solid $color_border-200 !default;

$header_background: $color_background-200 !default;

// Horizontal align of the searchbox if it's displayed as a layer - `$global_header-searchbox-as-layer-breakpoint`
$header_searchbox-justify-content: center !default;

// If you want to cover 'search' item in header by searchbox, provide proper negative margin based on your design
// It works only if '$global_header-searchbox-as-layer-breakpoint' has valid breakpoint and `$header_searchbox-justify-content` = flex-end
$header_searchbox-margin-to-cover-search-item: -6.8rem !default;

// Change in etc/view.xml if necessary
$header_is-sticky-on-mobile: deep-get(
    $view-xml,
    vars,
    Magento_Theme,
    header,
    mobile_is_sticky
) !default;

$header_sticky-top-position-mobile: 0 !default;
$header_sticky-top-position-tablet: $header_sticky-top-position-mobile !default;

/**
 * Variables related to sticky header and topbar on mobile devices.
 * to prevent page jumping when offacnvas (fixed positioned) is opened
 * If you have more elements on top, you have to include their height.
 */
$sticky-header-page-wrapper-offset-mobile: $global_header-height-mobile !default;
$sticky-header-page-wrapper-offset-tablet: $global_header-height-tablet !default;

.#{$ns}container {
    &--header {
        @include media('<laptop') {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@if (
    $header_is-sticky-on-mobile and $global_header-is-bottom-on-mobile != true
) {
    /**
     * Styles related to sticky header and topbar on mobile devices.
     * to prevent page jumping when offacnvas (fixed positioned) is opened
     */
    .navigation-offcanvas-open .page-wrapper.no-scroll-child {
        @include media('<laptop') {
            padding-top: $sticky-header-page-wrapper-offset-tablet;
        }

        @include media('<tablet') {
            padding-top: $sticky-header-page-wrapper-offset-mobile;
        }
    }

    .#{$ns}container {
        &--header {
            @include media('<laptop') {
                position: sticky;
                z-index: 800;
                top: $header_sticky-top-position-tablet;

                /**
                 * Fixed positioning required to work with offcanvas elements.
                 * Also, as a fallback for IE which does not support sticky.
                 */
                .navigation-offcanvas-open.no-scroll &,
                .ie11 {
                    position: fixed;
                }
            }

            @include media('<tablet') {
                top: $header_sticky-top-position-mobile;
            }
        }
    }
}

.#{$ns}header {
    $root: &;

    font-size: $header_font-size;

    @if ($header_is-sticky-on-mobile) {
        @if ($global_header-is-bottom-on-mobile) {
            height: 0;
        } @else {
            height: $global_header-height-mobile;
        }

        @include media('>=tablet') {
            height: $global_header-height-tablet;
        }
    }

    @include media('>=laptopLg') {
        height: $global_header-height-desktop;
    }

    &__content {
        height: $global_header-height-mobile;
        z-index: 300;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        background-color: $header_background;
        position: relative;
        max-width: 100vw;

        @if ($global_header-is-bottom-on-mobile) {
            @include media('<tablet') {
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        @include media('>=tablet') {
            height: $global_header-height-tablet;
        }

        @include media('>=laptop') {
            border-bottom: $header_border-bottom;
            flex-wrap: nowrap;
        }

        @include media('>=laptopLg') {
            height: $global_header-height-desktop;
        }
    }

    &__offcanvas-toggle {
        height: 100%;
        flex: 0 1 4rem;

        @if ($global_header-mobile-menu-is-right) {
            order: 4;
        }

        @include media('>=laptop') {
            display: none;
        }
    }

    &__logo {
        display: flex;
        flex: 1 1;
        margin-bottom: 0;
        height: 100%;
        padding: 0.7rem 0 0.7rem 0.7rem;

        @include media('>=phone') {
            padding: 0.7rem 0 0.7rem 1.5rem;
        }

        @include media('>=tablet') {
            flex: 0 1 auto;
            padding: 0;
        }

        .page-print & {
            @include container-wrapper();
            position: relative;
            height: auto;
            padding: 2rem 0;

            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }
    }

    &__search {
        display: none;
        width: 100%;
        background-color: $header_background;
        padding: 1rem;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 300;

        @if ($global_header-is-bottom-on-mobile) {
            bottom: $global_header-height-mobile;

            @include media('>=tablet') {
                bottom: auto;
                top: 100%;
            }
        } @else {
            top: 100%;
        }

        @if ($global_header-searchbox-as-layer-breakpoint) {
            @include media($global_header-searchbox-as-layer-breakpoint) {
                flex-grow: 1;
                width: auto;
                padding: 0;
                position: initial;
                margin-left: 2rem !important;
                justify-content: $header_searchbox-justify-content;
            }
        } @else {
            @include media('>=laptop') {
                padding: 1rem 0;
            }

            @include media('>=laptopLg') {
                padding: 1.5rem 0;
            }
        }

        &--active {
            display: flex;
        }

        @if ($global_header-hide-search-item-trigger-breakpoint) {
            @if ($global_header-hide-search-item-trigger-breakpoint == true) {
                display: flex;
            } @else {
                @include media(
                    $global_header-hide-search-item-trigger-breakpoint
                ) {
                    display: flex;
                }

                @if ($global_header-searchbox-as-layer-breakpoint) {
                    @include media(
                        $global_header-searchbox-as-layer-breakpoint
                    ) {
                        position: relative;
                        padding: 0;
                        top: initial;
                        display: contents;
                    }
                }
            }
        }

        @if (
            $global_header-hide-search-item-trigger-breakpoint and
                $header_searchbox-justify-content ==
                'flex-end'
        ) {
            @if ($global_header-hide-search-item-trigger-breakpoint == true) {
                margin: 0;
            } @else {
                margin-right: $header_searchbox-margin-to-cover-search-item;

                @include media(
                    $global_header-hide-search-item-trigger-breakpoint
                ) {
                    margin: 0;
                }
            }
        } @else {
            @if ($header_searchbox-justify-content == 'flex-end') {
                margin-right: $header_searchbox-margin-to-cover-search-item;
            }
        }
    }

    &__user-nav {
        display: flex;
        justify-content: flex-end;
        flex: 0 1 30%;

        @if ($global_header-hide-search-item-trigger-breakpoint) {
            @if ($global_header-hide-search-item-trigger-breakpoint == true) {
                flex: 0 1 25%;
            } @else {
                @include media(
                    $global_header-hide-search-item-trigger-breakpoint
                ) {
                    flex: 0 1 25%;
                }
            }
        }
    }

    &__back-button {
        border-right: 1px solid $color_border-500;
        height: 100%;
        display: flex;
        padding: 0 1rem;
        align-items: center;
        justify-content: center;
        min-width: 4rem;

        @include media('>=phone') {
            padding: 0 1.5rem;
        }

        @include media('>=tablet') {
            display: none;
        }
    }

    &__back-button-icon {
        fill: $color_border-700;
        height: 2.5rem;

        @include media('<phone') {
            height: 2rem;
        }
    }
}
