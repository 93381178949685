/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.cs-slider-navigation {
  display: none; }
  @media (hover: hover) and (pointer: fine) {
    .cs-slider-navigation {
      position: absolute;
      z-index: 11;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4.4rem;
      height: 5.2rem;
      border: 0;
      border-radius: 0px;
      background: #fff;
      box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
      padding: 0;
      color: #78b0e0;
      cursor: pointer;
      transition: all 0.3s; }
      .cs-slider-navigation--prev {
        left: 1.5rem; }
      .cs-slider-navigation--next {
        right: 1.5rem; }
      .cs-slider-navigation[disabled] {
        opacity: 0.25;
        cursor: not-allowed; }
        .cs-slider-navigation[disabled] svg {
          filter: grayscale(1); }
      .cs-slider-navigation:not([disabled]):hover {
        box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.25); }
      .cs-slider-navigation__icon {
        width: 1.4rem;
        height: 2.7rem;
        fill: currentColor; } }
