/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.cs-products-list {
  margin: 0;
  padding: 0;
  list-style: none; }
  .cs-products-list__item {
    margin: 34px 0;
    box-shadow: 0 0 7px 3px #eee;
    padding: 0 15px; }
  .cs-products-list:not(.cs-products-list--carousel) .cs-products-list__item {
    border-bottom: 1px solid #f0f0f0; }
    .cs-products-list:not(.cs-products-list--carousel) .cs-products-list__item:last-child {
      border-bottom: 0; }
  @media (min-width: 48em) {
    .cs-products-list--carousel .cs-products-list__item {
      border-bottom: 1px solid #f0f0f0;
      padding: 0; }
      .cs-products-list--carousel .cs-products-list__item:last-child {
        border-bottom: 0; } }
