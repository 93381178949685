.amcform-toolbar .amcform-submit.action.primary {
    min-width: 200px !important;
}

.amcform-toolbar {
    text-align: center;
}


.amform-versiontwo-label {
  font-weight: 700;
  font-size:  1.3rem !important;
}




.amcform-multi-page {
  .amform-versiontwo-label {
    word-break: unset;
  }

  .amform-checkbox,
  .checkbox-group {
    line-height: 110%;
  }
}
