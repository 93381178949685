@import 'config/colors';
@import 'config/variables';
@import 'vendors/include-media';


body {
  overflow-x: hidden;
}

.ecommerce-tuplex {
  //display: table-cell !important;
  //visibility: show;
  //visibility: hidden;
}

.promo {
  text-decoration: line-through;
}

.header.ecommerce-tuplex.links.cs-header__user-nav {
  display: flex;
}

.info_product_card {
  border: 1px solid;
}

.categorypath-katalog-produktow-poliweglan-poliweglan-kanalikowy-komorowy-bezbarwny,
.categorypath-katalog-produktow-poliweglan-poliweglan-kanalikowy-komorowy-brazowy,
.categorypath-katalog-produktow-poliweglan-poliweglan-kanalikowy-komorowy-opal {
  .col:nth-child(5) {
    width: 200px;
  }
}

.map-text-home {
  .cs-image-teaser__cta-button-span {
    &:before {
      content: "\f105";
      font-family: "Font Awesome 5 Pro";
      font-size: 22px;
      position: absolute;
      right: 17px;
      font-weight: 400;
    }
  }
}

.category-kim-jestesmy {
  .cs-button-component__amount {
    display: none;
  }
}

.price-final_price {
  .minimal-price {
    display: block;
  }
}

// .price-final_price {
//   .minimal-price {
//     display: none;
//   }
// }

.amasty_distance {
  margin-left: 18px;
}

.belka-podane-ceny {
  padding: 10px;
  background: $color-tuplex-yellow;
  font-size: 18px;


  a {
    color: $color-tuplex-dark-blue;
    padding: 0px;
  }

  .read-more a {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
    &::after {
    content: "\f105";
    font-family: "Font Awesome 5 Pro";
    width: 33px;
    height: 33px;
    font-weight: 400;
    margin-left: 10px;
    color: $color-tuplex-dark-blue;
    }
  }

  .text {
    max-width:$page_max-width;
    text-align: center;
    padding: 5px 0px;
    color: $color_black;
    margin: auto;
  }

  .pozostale {
    max-width:$page_max-width; 
    text-align: center;
    padding: 5px 0px;
    color: $color_black;
    margin: auto;
  }

  .read-more {
    max-width:$page_max-width;
    padding: 5px 0px;
    margin: auto;
    text-align: center;
  }
}

.cs-image-teaser__carousel{
  .cs-image-teaser__slides {
    picture{
      position: relative;
    }
  }
}

.swatch-attribute.swatch-layered.kolor{
  .swatch-attribute-options{
    .swatch-option-link-layered{
      .swatch-option.color, .swatch-option.image{
        box-shadow: 0px 0px 4px -2px #000;
      }
      span{
        display: flex;
        justify-content: center;
        color: #484848;
        padding: 3px 0px 10px 0px;
      }
    }
  }
}

.cs-category-links__list-wrapper{
  .cs-category-links__list{
      .cs-category-links__list-item{
          .cs-category-links__link{
              span{
                  display: none !important;
              }
          }
      }               
  }
}

@include media('>=desktop') {
  .checkout-cart-index{
    .cs-cart-page.cs-container__inner{
      .cart.table-wrapper.cs-cart-table-wrapper{
        .cs-cart-table__head{
          tr{
            th:first-child{
              width: 43%;
            }
          }
        }
      }
    }
  }
}
