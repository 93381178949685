@import 'config/variables';
@import 'utils/reset-list-style';
@import 'vendors/include-media';
@import 'config/breakpoints';
@import 'components/typography/mixin';
$page-title_font-size: $font-size_headline-1 !default;
$page-title_text-transform: none !default;
$page-title_font-weight: 700 !default;
$page-title_span-color: $color_text-500 !default;
$page-title_span-font-weight: 300 !default;
$page-title_span-padding: 0 !default;

.category-view {
  max-width: $page_max-width;
  margin: 0 auto;

  .page-title-wrapper {
    margin: 0 0 2rem;
    position: relative;

    &:before {
      content: " ";
      display: block;
      width: 97%;
      height: 1px;
      background-color: $color_alto;
      position: relative;
      top: 20px;
      margin: 0 auto;
      z-index: -1;
      @media(min-width: 1300px) {
        width: 100%;
      }
    }
  }
}

.page-title {
  @include headline($level: $page-title_font-size);
  padding-bottom: 1rem;
  text-transform: $page-title_text-transform;
  font-weight: $page-title_font-weight;
  margin: 0 auto;
  max-width: $page_max-width;
  display: table;
  text-align: left;
  position: relative;
  background-color: $color_white;
  z-index: 1;
  padding: 0 22px;
  font-size: 36px;
  color: $color-tuplex-text-normal;
  &__headline {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    display: inline;
    .base{
      text-transform: initial;
    }
  }

  &__span {
    color: $page-title_span-color;
    font-weight: $page-title_span-font-weight;
    padding: $page-title_span-padding;
  }
}

.amlocator-location-view {
  .page-title-wrapper {
    display: none;
  }
}

.amlocator-index-index {
  .page-title-wrapper {
    padding-top: 4rem;
    margin: 0 0 2rem;
    position: relative;

    &:before {
      content: " ";
      display: block;
      width: 97%;
      height: 1px;
      background-color: $color_alto;
      position: relative;
      top: 20px;
      margin: 0 auto;
      z-index: -1;
      @media(min-width: 1300px) {
        width: 100%;
      }
    }
  }
}

.page-products.catalog-category-view,
.page-products.catalogsearch-result-index {
  .cs-product-tile__addons {
    display: none;
  }
}

.products.list.items.product-items.cs-products-list__items {
  .cs-product-tile__name-link {
    display: flex !important;
    justify-content: space-between;

    &:before {
      display: none;
      content: "Zobacz więcej";
      min-width: 120px;
      height: 33px;
      font-weight: 400;
      align-items: center;
      justify-content: center;
      right: 33px;
      padding: 0 10px;
      font-size: 15px;
      border: 1px solid $color_crimson;
      color: $color_crimson;
      order: 4;
      margin-right: 30px;
      @include media('>=tablet') {
        display: flex;
      }
    }

    &:after {
      content: "\f105";
      font-family: "Font Awesome 5 Pro";
      width: 33px;
      height: 33px;
      font-weight: 400;
      display: block;
      position: absolute;
      right: 0;
      text-align: center;
      background: $color_crimson;
      color: $color-white;
    }
  }
}