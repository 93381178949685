@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';

.category-tuplex-international {
  .cs-image-teaser__slide {
    margin-right: 0;
    margin-bottom: 0 !important;
    width: 50% !important;
  }

  .cs-image-teaser__description {
      margin: 0;
      font-size: 0.8em;
      padding: 0 100px;
      letter-spacing: -0.18px;
      line-height: 1.3;
      color: inherit;
  }

  .cs-image-teaser__slogan {
    font-size: 1.1em;
    margin-bottom: 10px;
  }

  .cs-page-category__cc-top {
    //max-width: $page_max-width;
    margin: 0 auto;
  }
}




.cms-kariera  {
  .cs-image-teaser__slide {
    margin-right: 0;
    margin-bottom: 0 !important;
    width: 50% !important;
  }

  .cs-page-category__cc-top {
    max-width: $page_max-width;
    margin: 0 auto;
  }
}
