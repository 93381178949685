/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
.tuplex-table {
  margin: 2rem auto;
  width: 100%; }
  @media (min-width: 64em) {
    .tuplex-table {
      width: 75%; } }
  .tuplex-table th,
  .tuplex-table td {
    width: 33.333%; }
  .tuplex-table thead {
    background-color: #78b0e0;
    color: #fff; }
    .tuplex-table thead th:not(:last-child) {
      border-right: 2px solid #fff; }
  .tuplex-table tbody tr:nth-child(even) td {
    background-color: #f0f0f0; }
  .tuplex-table tbody td:not(:last-child) {
    border-right: 2px solid #fff; }

.table-wrapper.grouped .price-and-cart {
  flex-direction: column;
  align-items: center;
  margin-top: 10px; }
  .table-wrapper.grouped .price-and-cart button {
    margin: 15px 0; }

.table-wrapper.grouped .rows.tier-price.summary.qty > .col.qty {
  position: relative; }

.table-wrapper.grouped .qty.summary-wrapper-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white; }
