/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
/***
 * Hide native browser's scrollbar
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* prettier-ignore */
.cs-minicart {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: space-between; }
  .cs-minicart__wrapper {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    min-height: 0; }
  .cs-minicart__content-wrapper {
    max-height: calc(100% - 5.1rem);
    overflow-y: auto;
    overflow-x: hidden; }
  .cs-minicart__close {
    display: inline-block;
    background: none;
    box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    position: absolute;
    right: -1.5rem;
    width: 5rem;
    height: 5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    right: 0.5rem; }
    .cs-minicart__close:focus, .cs-minicart__close:active, .cs-minicart__close:hover {
      background: transparent;
      border: none;
      color: inherit; }
  .cs-minicart__close-icon {
    position: relative;
    transform: rotate(45deg);
    width: 100%;
    height: 100%;
    display: inline-block; }
    .cs-minicart__close-icon:before, .cs-minicart__close-icon:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 2rem;
      height: 0.2rem;
      left: auto;
      background-color: #484848;
      transition: transform 0.3s ease-out, opacity 0.15s ease-out, background-color 0.15s linear;
      backface-visibility: hidden; }
    .cs-minicart__close-icon:hover:before, .cs-minicart__close-icon:hover:after {
      background-color: #696969; }
    .cs-minicart__close-icon[aria-expanded="true"]:before, .cs-minicart__close-icon[aria-expanded="true"]:after {
      background-color: #888; }
    .cs-minicart__close-icon:before, .cs-minicart__close-icon:after {
      right: 1.5rem;
      transform: translate3d(0, -50%, 0) !important; }
    .cs-minicart__close-icon:after {
      transform: translate3d(0, -50%, 0) rotate(90deg) !important; }
    .cs-minicart__close-icon[aria-expanded="true"]:before {
      transform: translate3d(0, -50%, 0) rotate(-90deg) !important;
      opacity: 0; }
    .cs-minicart__close-icon[aria-expanded="true"]:after {
      transform: translate3d(0, -50%, 0) rotate(0) !important; }
  .cs-minicart__close-text {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .cs-minicart__title-wrapper {
    position: relative;
    height: 5.1rem;
    padding: 2rem 0;
    display: flex;
    color: #484848;
    font-weight: 700;
    font-size: 1.8rem;
    margin: 0;
    padding: 1rem 2rem;
    font-size: 2.1rem;
    text-transform: uppercase;
    border-bottom: 1px solid #dcdcdc; }
  .cs-minicart__items-total {
    margin-left: 0.5rem;
    color: #696969;
    font-weight: 400; }
    .cs-minicart__items-total:not(.cs-minicart__items-total--empty):before {
      content: '('; }
    .cs-minicart__items-total:not(.cs-minicart__items-total--empty):after {
      content: ')'; }
    .cs-minicart__items-total--empty .count {
      display: none; }
  .cs-minicart__edit-link {
    font-weight: 700; }
    .cs-minicart__edit-link .cs-link__icon {
      padding: 0 0 0 1rem; }
  .cs-minicart__content {
    height: auto !important;
    background-color: #fff;
    position: relative; }
    .cs-minicart__content .load.indicator:before {
      height: unset; }
  .cs-minicart__list {
    padding: 0;
    list-style: none;
    margin-bottom: 0; }
    .cs-minicart__list--scroll {
      height: auto !important;
      max-height: none;
      overflow-y: hidden; }
  .cs-minicart__list-item {
    padding: 1.5rem;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 0;
    padding: 1.5rem 2rem; }
    .cs-minicart__list-item:last-child {
      border-bottom: none; }
  .cs-minicart__actions-wrapper {
    padding: 2rem 0;
    position: relative;
    padding: 1.5rem 2rem;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.27);
    min-height: 16.4rem;
    position: static;
    z-index: 1; }
    .cs-minicart__actions-wrapper:has(.cs-minicart__empty) {
      box-shadow: none; }
  .cs-minicart__actions {
    margin-top: 1rem; }
    .cs-minicart__actions:first-child {
      margin-top: 0; }
  .cs-minicart__empty {
    padding: 2rem 0;
    display: inline-block;
    font-size: 1.4rem;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 5.1rem;
    left: 0; }
  .cs-minicart__subtotal {
    font-weight: 700;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2rem; }
  .cs-minicart__subtotal-price .price {
    font-weight: 700;
    font-size: 1.5rem !important;
    font-size: 2.1rem !important; }
  .cs-minicart__button-checkout {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    width: 100%; }
    .cs-minicart__button-checkout:hover, .cs-minicart__button-checkout:focus, .cs-minicart__button-checkout:active {
      background-color: #78b0e0 !important; }
    .cs-minicart__button-checkout:before, .cs-minicart__button-checkout:after {
      content: '';
      position: absolute;
      top: -1px;
      bottom: -1px;
      right: -1px; }
    .cs-minicart__button-checkout:before {
      width: 5.5rem;
      border-radius: 0 0px 0px 0;
      z-index: -1;
      background: linear-gradient(100deg, transparent 0%, transparent 15%, #346097 15%, #346097 100%); }
    .cs-minicart__button-checkout:after {
      width: 4rem;
      min-width: auto;
      background-color: #346097;
      transition: width 0.25s linear;
      z-index: -2;
      border-radius: 0px; }
    .cs-minicart__button-checkout:hover:after {
      width: calc(100% + 2px); }
    .cs-minicart__button-checkout::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-minicart__button-checkout, .cs-minicart__button-checkout:visited {
      cursor: pointer;
      border-radius: 0px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #78b0e0; }
      .cs-minicart__button-checkout *, .cs-minicart__button-checkout:visited * {
        color: #fff;
        fill: #fff; }
    .cs-minicart__button-checkout:hover:not([disabled]), .cs-minicart__button-checkout:focus:not([disabled]), .cs-minicart__button-checkout:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid transparent;
      color: #fff;
      background-color: #346097; }
      .cs-minicart__button-checkout:hover:not([disabled]) *, .cs-minicart__button-checkout:focus:not([disabled]) *, .cs-minicart__button-checkout:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-minicart__button-checkout[disabled] {
      opacity: 0.65;
      cursor: auto; }
    .cs-minicart__button-checkout-span {
      margin-top: 0;
      z-index: 1;
      font-weight: 700;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal;
      padding-left: 0;
      padding-right: 4em;
      /* stylelint-disable-next-line */ }
    .cs-minicart__button-checkout-icon {
      max-width: 1em;
      max-height: 1.4rem;
      z-index: 2;
      height: auto;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      margin-right: 0;
      right: 1em;
      /* stylelint-disable-next-line */ }
  .cs-minicart__button-viewcart {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    width: 100%; }
    .cs-minicart__button-viewcart::before, .cs-minicart__button-viewcart::after {
      content: none; }
    .cs-minicart__button-viewcart::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-minicart__button-viewcart, .cs-minicart__button-viewcart:visited {
      cursor: pointer;
      border-radius: 0px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid #78b0e0;
      color: #78b0e0;
      background-color: #fff; }
      .cs-minicart__button-viewcart *, .cs-minicart__button-viewcart:visited * {
        color: #78b0e0;
        fill: #78b0e0; }
    .cs-minicart__button-viewcart:hover:not([disabled]), .cs-minicart__button-viewcart:focus:not([disabled]), .cs-minicart__button-viewcart:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid #346097;
      color: #fff;
      background-color: #346097; }
      .cs-minicart__button-viewcart:hover:not([disabled]) *, .cs-minicart__button-viewcart:focus:not([disabled]) *, .cs-minicart__button-viewcart:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-minicart__button-viewcart[disabled] {
      opacity: 0.65;
      cursor: auto; }
    .cs-minicart__button-viewcart-span {
      margin-top: 0;
      z-index: 1;
      font-weight: 700;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal;
      padding-left: 0;
      padding-right: 4em;
      /* stylelint-disable-next-line */ }
    .cs-minicart__button-viewcart-icon {
      max-width: 1em;
      max-height: 1.4rem;
      z-index: 2;
      height: auto;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      margin-right: 0;
      right: 1em;
      /* stylelint-disable-next-line */ }
  .cs-minicart__button-carousel {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5; }
    .cs-minicart__button-carousel-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 3rem;
      margin: 3rem 0;
      border-top: 1px solid #dcdcdc; }
    .cs-minicart__button-carousel::before, .cs-minicart__button-carousel::after {
      content: none; }
    .cs-minicart__button-carousel::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-minicart__button-carousel, .cs-minicart__button-carousel:visited {
      cursor: pointer;
      border-radius: 0px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid #78b0e0;
      color: #78b0e0;
      background-color: #fff; }
      .cs-minicart__button-carousel *, .cs-minicart__button-carousel:visited * {
        color: #78b0e0;
        fill: #78b0e0; }
    .cs-minicart__button-carousel:hover:not([disabled]), .cs-minicart__button-carousel:focus:not([disabled]), .cs-minicart__button-carousel:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid #346097;
      color: #fff;
      background-color: #346097; }
      .cs-minicart__button-carousel:hover:not([disabled]) *, .cs-minicart__button-carousel:focus:not([disabled]) *, .cs-minicart__button-carousel:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-minicart__button-carousel[disabled] {
      opacity: 0.65;
      cursor: auto; }
    .cs-minicart__button-carousel-span {
      margin-top: 0;
      z-index: 1;
      font-weight: 700;
      vertical-align: baseline;
      display: inline-block;
      line-height: 1.275em;
      letter-spacing: normal;
      padding-left: 0;
      padding-right: 4em;
      /* stylelint-disable-next-line */ }
    .cs-minicart__button-carousel-icon {
      max-width: 1em;
      max-height: 1.4rem;
      z-index: 2;
      height: auto;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      margin-right: 0;
      right: 1em;
      /* stylelint-disable-next-line */ }
  .cs-minicart__messages {
    padding: 0 2rem; }
  .cs-minicart-offcanvas__wrapper {
    padding: 0;
    height: 100%; }

/* prettier-ignore */
.cs-minicart__carousel {
  position: relative;
  z-index: 10; }

@media screen and (max-width: 79.99em) and (max-height: 900px) {
  .cs-minicart__carousel {
    max-width: 60rem;
    margin: 0 auto; } }
  .cs-minicart__carousel-container {
    opacity: 0;
    transition: opacity 0.3s ease-in;
    background: #fff; }
    .cs-minicart__carousel-wrapper--ready .cs-minicart__carousel-container {
      opacity: 1; }
  .cs-minicart__carousel-wrapper {
    display: none;
    visibility: hidden;
    pointer-events: none;
    top: 50%;
    right: calc(40rem + 2rem);
    transform: translateY(-50%);
    width: calc(100vw - (40rem + (2 * 2rem)));
    max-width: 120rem;
    padding: 0;
    margin: 0;
    background: transparent; }
    .cs-minicart__carousel-wrapper--loading, .cs-minicart__carousel-wrapper--ready {
      visibility: visible;
      pointer-events: auto; }
    .drawer--visible .cs-minicart__carousel-wrapper {
      position: absolute; }
    @media (min-width: 64em) {
      .minicart-offcanvas-open .cs-minicart__carousel-wrapper {
        display: block; } }
    @media (min-width: 80em) {
      .cs-minicart__carousel-wrapper {
        right: calc(40rem + (2 * 2rem));
        width: calc(100vw - (40rem + (4 * 2rem))); } }
    .cs-minicart__carousel-wrapper .cs-headline {
      margin: 0 0 2rem;
      padding: 2rem 0 0; }
    .cs-minicart__carousel-wrapper .cs-headline__title {
      font-family: "Lato", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.8rem;
      line-height: 1.1;
      color: #484848;
      font-weight: 700;
      text-transform: uppercase;
      margin: 1rem 0 2rem; }
  .cs-minicart__carousel-spinner {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 11rem;
    height: 11rem;
    padding: 2rem 0;
    transform: translate3d(-50%, -50%, 0);
    transition: opacity 0.3s ease-in; }
    .cs-minicart__carousel-wrapper--loading .cs-minicart__carousel-spinner {
      opacity: 1;
      z-index: 10; }
  .cs-minicart__carousel .cs-products-carousel {
    --gap: 0.8rem;
    --base-size: calc(100% - 0.8rem);
    --size: var(--base-size);
    /**
     * class-based columns config relates to classnames, like '--items-in-row-4'
     * which are added to elements, depending on the CC Components configuration.
     */
    --size: calc(
            var(--base-size) /
                2 -
                1 *
                var(--gap) /
                2
        );
    /*  container padding fix  */
    margin: 0;
    background: #fff; }
    @media (min-width: 83.125em) {
      .cs-minicart__carousel .cs-products-carousel.cs-minicart__carousel .cs-products-carousel--container-width {
        --base-size: calc(130rem - 0.8rem); }
      .cs-minicart__carousel .cs-products-carousel.cs-minicart__carousel .cs-products-carousel--use-whole-screen {
        --base-size: 100%;
        max-width: 100%; }
        .cs-minicart__carousel .cs-products-carousel.cs-minicart__carousel .cs-products-carousel--use-whole-screen .cs-minicart__carousel .cs-products-carousel__slides {
          scroll-snap-type: x proximity;
          padding-left: calc((100% - 130rem) / 2);
          padding-right: calc((100% - 130rem) / 2);
          scroll-padding-left: calc((100% - 130rem) / 2);
          scroll-padding-right: calc((100% - 130rem) / 2);
          scroll-margin-left: calc((100vw - 130rem) / 2);
          scroll-margin-right: calc((100vw - 130rem) / 2); } }
    @media (hover: hover) and (pointer: fine) and (min-width: 83.125em) {
      .cs-minicart__carousel .cs-products-carousel.cs-minicart__carousel .cs-products-carousel--container-width {
        --base-size: 130rem; } }
    @media (min-width: 48em) {
      .cs-minicart__carousel .cs-products-carousel {
        --gap: 0.8rem; } }
    @media (min-width: 23.125em) {
      .cs-minicart__carousel .cs-products-carousel {
        --size: calc(
                    var(--base-size) / 2 - ((
                                    1 * var(--gap)
                                ) / 2)
                ); } }
    @media (min-width: 30em) {
      .cs-minicart__carousel .cs-products-carousel {
        --size: calc(
                    var(--base-size) / 2 - ((
                                    1 * var(--gap)
                                ) / 2)
                ); } }
    @media (min-width: 48em) {
      .cs-minicart__carousel .cs-products-carousel {
        --size: calc(
                    var(--base-size) / 2 - ((
                                    1 * var(--gap)
                                ) / 2)
                ); } }
    @media (min-width: 64em) {
      .cs-minicart__carousel .cs-products-carousel {
        --size: calc(
                    var(--base-size) / 2 - ((
                                    1 * var(--gap)
                                ) / 2)
                ); } }
    @media (min-width: 80em) {
      .cs-minicart__carousel .cs-products-carousel {
        --size: calc(
                    var(--base-size) / 3 - ((
                                    2 * var(--gap)
                                ) / 3)
                ); } }
    @media (min-width: 90em) {
      .cs-minicart__carousel .cs-products-carousel {
        --size: calc(
                    var(--base-size) / 4 - ((
                                    3 * var(--gap)
                                ) / 4)
                ); } }
    @media (min-width: 120em) {
      .cs-minicart__carousel .cs-products-carousel {
        --size: calc(
                    var(--base-size) / 4 - ((
                                    3 * var(--gap)
                                ) / 4)
                ); } }
    @media (max-width: 83.0625em) {
      .cs-minicart__carousel .cs-products-carousel.cs-minicart__carousel .cs-products-carousel--container-width .cs-minicart__carousel .cs-products-carousel__slides {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        scroll-padding-left: 1.5rem;
        scroll-padding-right: 1.5rem; } }
    @media (prefers-reduced-motion: no-preference) {
      .cs-minicart__carousel .cs-products-carousel .cs-minicart__carousel .cs-products-carousel__slides {
        scroll-behavior: smooth; } }
    .cs-minicart__carousel .cs-products-carousel .cs-minicart__carousel .cs-products-carousel__slides-wrapper {
      position: relative; }
    .cs-minicart__carousel .cs-products-carousel .cs-minicart__carousel .cs-products-carousel__slides {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex: 0 0 100%;
      gap: 0;
      flex-wrap: nowrap;
      padding: var(--gap) 0;
      overflow-x: auto;
      overscroll-behavior-inline: contain;
      scrollbar-width: thin; }
      @supports (scroll-behavior: smooth) {
        .cs-minicart__carousel .cs-products-carousel .cs-minicart__carousel .cs-products-carousel__slides {
          scroll-snap-type: x mandatory; } }
      @media (min-width: 48em) {
        .cs-minicart__carousel .cs-products-carousel .cs-minicart__carousel .cs-products-carousel__slides {
          overflow: -moz-scrollbars-none;
          scrollbar-width: none; }
          .cs-minicart__carousel .cs-products-carousel .cs-minicart__carousel .cs-products-carousel__slides::-webkit-scrollbar {
            display: none;
            width: 0;
            height: 0;
            background: transparent; }
          .cs-minicart__carousel .cs-products-carousel .cs-minicart__carousel .cs-products-carousel__slides::-webkit-scrollbar-thumb {
            background: transparent;
            width: 0;
            height: 0; } }
    .cs-minicart__carousel .cs-products-carousel .cs-minicart__carousel .cs-products-carousel__slide {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-block;
      flex: 0 0 var(--size);
      margin-right: var(--gap);
      scroll-snap-align: start;
      min-width: 0; }
      .cs-minicart__carousel .cs-products-carousel .cs-minicart__carousel .cs-products-carousel__slide:last-of-type {
        margin-right: 0; }
    .cs-minicart__carousel .cs-products-carousel .cs-minicart__carousel .cs-products-carousel__image {
      display: block; }

.minicart-wrapper .ui-dialog {
  display: block !important; }
