@import 'config/colors';
@import 'vendors/include-media';

.cms-home {
  .cs-image-teaser__content {
    @include media('<=laptop') {
      padding: 0 0 20px 0;
    }

    .cs-image-teaser__description {
      font-size: 16px !important;
    }

    .cs-image-teaser__slide--scheme-light .cs-image-teaser__slogan {
      font-size: 3.8em;
    }

    .cs-image-teaser__slide--scheme-dark .cs-image-teaser__slogan {
      font-size: 20px !important;
    }

    .cs-headline__subtitle {
      line-height: 2;
      font-size: 1.8em;
    }

    .cs-paragraph.cs-paragraph--cols-none.cs-paragraph--mode- {
      width: max-content;
      position: relative;
      margin: 2rem auto;

      &:after {
        content: "\f105";
        font-family: "Font Awesome 5 Pro";
        font-size: 22px;
        position: absolute;
        right: 17px;
        top: 5px;
        z-index: 5;
        font-weight: 400;
        color: $color-white;
      }
    }

    .cs-container.cs-container--image-teaser:last-child {
      .cs-image-teaser__slogan {
        font-size: 2.8rem;
      }
    }

    .cs-image-teaser__cta {
      font-size: 14px !important;
    }
  }

  .button-teaser {
    display: none;
  }

  .cs-container.cs-container--paragraph {
    .cs-cms-block {
      div {
        display: flex;
        justify-content: center;

        .cs-image-teaser__cta-button {
          background-color: #346097;

          &::after,
          &::before {
            width: 100%;

            &:hover {
              background-color: #346097;
            }
          }

          .cs-image-teaser__cta-button-span {
            padding: 0 2rem;
            font-size: 2rem;
          }
        }
      }
    }
  }

  .cs-container.cs-container--image-teaser.cs-container--teaser-and-text {
    margin-bottom: 0 !important;

    .cs-image-teaser__slides.cs-image-teaser__slides.cs-image-teaser__slides--mobile_image-text {
      gap: 0 !important;
    }

    .cs-image-teaser__text-content {
      .cs-image-teaser__slogan {
        font-size: 32px !important;
        font-weight: 700 !important;
      }

      .cs-image-teaser__description {
        font-size: 16px !important;
      }

      .cs-image-teaser__cta span.cs-image-teaser__cta-button-span {
        font-size: 16px !important;
      }
    }
  }

  .cs-container.cs-container--button .cs-container__inner {
    margin-top: 1rem;
  }
}