/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.page-layout-strefa-wiedzy {
  font-size: 1.6rem; }
  .page-layout-strefa-wiedzy h2:not(.cs-category-links__title):not(.cs-footer__section-title):not(.footer-tuplex):not(.newsletter-title) {
    margin: 3rem 0 3rem;
    font-size: 2rem; }
    .page-layout-strefa-wiedzy h2:not(.cs-category-links__title):not(.cs-footer__section-title):not(.footer-tuplex):not(.newsletter-title):before {
      content: "\f672";
      color: #FFEF45;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      display: inline-block;
      margin-left: 0em;
      width: 50px;
      height: 50px;
      font-size: 24px;
      padding: 15px 10px;
      background: #346097;
      border-radius: 50%;
      margin-right: 20px; }
  .page-layout-strefa-wiedzy p {
    text-align: justify; }
    .page-layout-strefa-wiedzy p img {
      margin-top: 20px;
      width: 100%; }
  .page-layout-strefa-wiedzy #button {
    margin-top: 2rem; }
  .page-layout-strefa-wiedzy .cs-button-component__span {
    min-width: 250px;
    font-size: 20px; }
  .page-layout-strefa-wiedzy .cs-paragraph img {
    width: 100%; }
  @media (min-width: 48em) {
    .page-layout-strefa-wiedzy .cs-paragraph {
      margin: 0 0 0rem; } }
  .page-layout-strefa-wiedzy .cs-paragraph ul {
    margin-top: 2rem;
    list-style: none;
    padding: 3rem 3rem 3rem 6rem;
    background-color: #f0f0f0; }
  .page-layout-strefa-wiedzy .cs-paragraph ul > li:before {
    content: "\f00c";
    color: #82cad4;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    display: inline-block;
    margin-left: -1.9em;
    width: 1.9em; }

cs-category-links__dropdown cs-category-links-dropdown
.cs-category-links__list a {
  font-size: 30px !important; }

.cs-category-links__list-all {
  display: none; }

.cs-category-links__list-item {
  display: inline-block;
  margin: 1rem 0 0 2.5rem; }

.category-strefa-wiedzy h2.cs-category-links__title {
  display: none; }

.elewacje-wentylowane p:first-child, .strefa-wiedzy-szachownica p:first-child {
  padding-top: 150px; }
  @media (max-width: 63.99em) {
    .elewacje-wentylowane p:first-child, .strefa-wiedzy-szachownica p:first-child {
      padding-top: 50px; } }

.col-md-4, .col-md-9 {
  padding: 1rem; }

.col-lg-3, .col-lg-6 {
  padding: 2rem; }
