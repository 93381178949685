/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior, .category-10-tys-przylbic-dla-polskich-szpitali, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow {
  font-size: 1.6rem; }
  .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku h2, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior h2, .category-10-tys-przylbic-dla-polskich-szpitali h2, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow h2 {
    margin: 3rem 0 3rem; }
  .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku h3, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior h3, .category-10-tys-przylbic-dla-polskich-szpitali h3, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow h3 {
    margin: 3rem 0 3rem; }
    .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku h3:before, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior h3:before, .category-10-tys-przylbic-dla-polskich-szpitali h3:before, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow h3:before {
      content: "\f672";
      color: #FFEF45;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      display: inline-block;
      margin-left: 0em;
      width: 50px;
      height: 50px;
      font-size: 24px;
      padding: 15px 10px;
      background: #346097;
      border-radius: 50%;
      margin-right: 20px; }
  .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku table, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior table, .category-10-tys-przylbic-dla-polskich-szpitali table, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow table {
    margin-bottom: 2rem;
    border: 1px solid #484848;
    font-weight: 900;
    background: #346097;
    color: #fff; }
  .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku th, .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku td, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior th, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior td, .category-10-tys-przylbic-dla-polskich-szpitali th, .category-10-tys-przylbic-dla-polskich-szpitali td, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow th, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow td {
    padding: 15px;
    text-align: left;
    border: 1px solid #fff; }
    .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku th p, .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku td p, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior th p, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior td p, .category-10-tys-przylbic-dla-polskich-szpitali th p, .category-10-tys-przylbic-dla-polskich-szpitali td p, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow th p, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow td p {
      margin: 0; }
  .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku th, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior th, .category-10-tys-przylbic-dla-polskich-szpitali th, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow th {
    background-color: #346097;
    color: #fff; }
  .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku p, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior p, .category-10-tys-przylbic-dla-polskich-szpitali p, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow p {
    text-align: justify; }
    .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku p img, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior p img, .category-10-tys-przylbic-dla-polskich-szpitali p img, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow p img {
      margin-top: 20px;
      width: 100%; }
  .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku #button, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior #button, .category-10-tys-przylbic-dla-polskich-szpitali #button, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow #button {
    margin-top: 2rem; }
  .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku .cs-button-component__span, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior .cs-button-component__span, .category-10-tys-przylbic-dla-polskich-szpitali .cs-button-component__span, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow .cs-button-component__span {
    min-width: 250px;
    font-size: 20px; }
  .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku .cs-paragraph img, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior .cs-paragraph img, .category-10-tys-przylbic-dla-polskich-szpitali .cs-paragraph img, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow .cs-paragraph img {
    width: 100%; }
  @media (min-width: 48em) {
    .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku .cs-paragraph, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior .cs-paragraph, .category-10-tys-przylbic-dla-polskich-szpitali .cs-paragraph, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow .cs-paragraph {
      margin: 0 0 0rem; } }
  .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku .cs-paragraph ul, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior .cs-paragraph ul, .category-10-tys-przylbic-dla-polskich-szpitali .cs-paragraph ul, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow .cs-paragraph ul {
    margin-top: 2rem;
    list-style: none;
    padding: 3rem 3rem 3rem 6rem;
    background-color: #f0f0f0; }
  .categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku .cs-paragraph ul > li:before, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior .cs-paragraph ul > li:before, .category-10-tys-przylbic-dla-polskich-szpitali .cs-paragraph ul > li:before, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow .cs-paragraph ul > li:before {
    content: "\f00c";
    color: #82cad4;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    display: inline-block;
    margin-left: -1.9em;
    width: 1.9em; }
