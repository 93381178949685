/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Mixins for flexible containers declaration.
 */
/**
 * Defines page container. This mixin makes sure that the content has always equal padding
 * for mobile screens on both size of the device.
 */
/**
 * Negates parent container's padding and sets it own.
 * Somethimes this has to be done to make background even to other containers' content.
 */
/**
 * Defines container wrapper that makes sure contnet doesn't extend above certain width and is always centered.
 */
.cs-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box; }
  .cs-container__inner {
    max-width: 130rem;
    width: 100%;
    margin: 0 auto;
    position: relative; }
    .cs-container__inner:before, .cs-container__inner:after {
      content: '';
      display: table;
      clear: both; }
  .cs-container__aside {
    width: 100%; }
    @media (min-width: 48em) {
      .cs-container__aside {
        width: 40%; } }
    @media (min-width: 64em) {
      .cs-container__aside {
        width: 32%; } }
    @media (min-width: 90em) {
      .cs-container__aside {
        width: 30%; } }
  .cs-container__main-column {
    width: 56%;
    position: relative; }
    @media (min-width: 64em) {
      .cs-container__main-column {
        width: 65%; } }
    @media (min-width: 90em) {
      .cs-container__main-column {
        width: 67%; } }
    @media (max-width: 47.99em) {
      .cs-container__main-column {
        max-width: 100% !important;
        width: 100% !important; } }
    @media (min-width: 64em) {
      .cs-container__main-column--space-top {
        padding-top: 3rem; } }
  .cs-container--contrast {
    background-color: #f0f0f0; }
    @media (min-width: 64em) {
      .cs-container--contrast {
        background-image: url("../images/background.png");
        background-size: cover;
        background-repeat: no-repeat; } }
  .cs-container--spacious {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media (min-width: 64em) {
      .cs-container--spacious {
        padding-top: 3rem;
        padding-bottom: 3rem; } }
  .cs-container--noscroll {
    overflow: hidden; }
  .cs-container--ontop {
    z-index: 301; }
  .cs-container--viewport-width {
    max-width: none; }
