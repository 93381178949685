/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.block.discount.cs-discount.cs-discount--coupon .content.cs-discount__content {
  display: block !important; }

.block.discount.cs-discount.cs-discount--coupon #discount-coupon-form .field > .label {
  color: red;
  font-size: 1.7rem; }

.block.discount.cs-discount.cs-discount--coupon #discount-coupon-form .control > .input-text {
  border: 2px solid black; }

.block.discount.cs-discount.cs-discount--coupon #discount-coupon-form .actions-toolbar.cs-discount__buttons > button {
  border: 2px solid #77b1e0; }
