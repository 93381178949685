/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
#mySwitch {
  display: flex;
  order: 10;
  /* Rounded sliders */ }
  #mySwitch .switch {
    transform: rotate(90deg);
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px; }
  #mySwitch .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  #mySwitch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #dcdcdc;
    -webkit-transition: .4s;
    transition: .4s; }
  #mySwitch .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s; }
  #mySwitch input:checked + .slider {
    background-color: #78b0e0; }
  #mySwitch input:focus + .slider {
    box-shadow: 0 0 1px #78b0e0; }
  #mySwitch input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    left: 0px; }
  #mySwitch .slider.round {
    border-radius: 34px; }
  #mySwitch .slider.round:before {
    border-radius: 50%; }
