/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
@media (max-width: 64em) {
  .cms-home .cs-image-teaser__content {
    padding: 0 0 20px 0; } }

.cms-home .cs-image-teaser__content .cs-image-teaser__description {
  font-size: 16px !important; }

.cms-home .cs-image-teaser__content .cs-image-teaser__slide--scheme-light .cs-image-teaser__slogan {
  font-size: 3.8em; }

.cms-home .cs-image-teaser__content .cs-image-teaser__slide--scheme-dark .cs-image-teaser__slogan {
  font-size: 20px !important; }

.cms-home .cs-image-teaser__content .cs-headline__subtitle {
  line-height: 2;
  font-size: 1.8em; }

.cms-home .cs-image-teaser__content .cs-paragraph.cs-paragraph--cols-none.cs-paragraph--mode- {
  width: max-content;
  position: relative;
  margin: 2rem auto; }
  .cms-home .cs-image-teaser__content .cs-paragraph.cs-paragraph--cols-none.cs-paragraph--mode-:after {
    content: "\f105";
    font-family: "Font Awesome 5 Pro";
    font-size: 22px;
    position: absolute;
    right: 17px;
    top: 5px;
    z-index: 5;
    font-weight: 400;
    color: #fff; }

.cms-home .cs-image-teaser__content .cs-container.cs-container--image-teaser:last-child .cs-image-teaser__slogan {
  font-size: 2.8rem; }

.cms-home .cs-image-teaser__content .cs-image-teaser__cta {
  font-size: 14px !important; }

.cms-home .button-teaser {
  display: none; }

.cms-home .cs-container.cs-container--paragraph .cs-cms-block div {
  display: flex;
  justify-content: center; }
  .cms-home .cs-container.cs-container--paragraph .cs-cms-block div .cs-image-teaser__cta-button {
    background-color: #346097; }
    .cms-home .cs-container.cs-container--paragraph .cs-cms-block div .cs-image-teaser__cta-button::after, .cms-home .cs-container.cs-container--paragraph .cs-cms-block div .cs-image-teaser__cta-button::before {
      width: 100%; }
      .cms-home .cs-container.cs-container--paragraph .cs-cms-block div .cs-image-teaser__cta-button::after:hover, .cms-home .cs-container.cs-container--paragraph .cs-cms-block div .cs-image-teaser__cta-button::before:hover {
        background-color: #346097; }
    .cms-home .cs-container.cs-container--paragraph .cs-cms-block div .cs-image-teaser__cta-button .cs-image-teaser__cta-button-span {
      padding: 0 2rem;
      font-size: 2rem; }

.cms-home .cs-container.cs-container--image-teaser.cs-container--teaser-and-text {
  margin-bottom: 0 !important; }
  .cms-home .cs-container.cs-container--image-teaser.cs-container--teaser-and-text .cs-image-teaser__slides.cs-image-teaser__slides.cs-image-teaser__slides--mobile_image-text {
    gap: 0 !important; }
  .cms-home .cs-container.cs-container--image-teaser.cs-container--teaser-and-text .cs-image-teaser__text-content .cs-image-teaser__slogan {
    font-size: 32px !important;
    font-weight: 700 !important; }
  .cms-home .cs-container.cs-container--image-teaser.cs-container--teaser-and-text .cs-image-teaser__text-content .cs-image-teaser__description {
    font-size: 16px !important; }
  .cms-home .cs-container.cs-container--image-teaser.cs-container--teaser-and-text .cs-image-teaser__text-content .cs-image-teaser__cta span.cs-image-teaser__cta-button-span {
    font-size: 16px !important; }

.cms-home .cs-container.cs-container--button .cs-container__inner {
  margin-top: 1rem; }
