/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
.cms-kariera .column.main {
  width: auto;
  padding: 0rem 0 0;
  max-width: 100%;
  min-height: 10rem; }

.cms-kariera .cs-container.cs-container--teaser-and-text .cs-image-teaser__content-wrapper {
  position: absolute; }

.cms-kariera .cs-container.cs-container--teaser-and-text .cs-image-teaser__wrapper--content-over .cs-image-teaser__content-wrapper {
  padding: 3.76%; }

.cms-kariera .cs-image-teaser__slogan {
  font-size: 2.8em; }

.cms-kariera .cs-image-teaser__text-content {
  font-size: 12px !important; }

.cms-kariera #button {
  margin-top: 40px; }
