/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-button {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: none;
  padding: 0.75em 1em;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5; }
  .cs-button:hover, .cs-button:focus, .cs-button:active {
    background-color: #78b0e0 !important; }
  .cs-button:before, .cs-button:after {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px; }
  .cs-button:before {
    width: 5.5rem;
    border-radius: 0 0px 0px 0;
    z-index: -1;
    background: linear-gradient(100deg, transparent 0%, transparent 15%, #346097 15%, #346097 100%); }
  .cs-button:after {
    width: 4rem;
    min-width: auto;
    background-color: #346097;
    transition: width 0.25s linear;
    z-index: -2;
    border-radius: 0px; }
  .cs-button:hover:after {
    width: calc(100% + 2px); }
  .cs-button::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .cs-button, .cs-button:visited {
    cursor: pointer;
    border-radius: 0px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #78b0e0; }
    .cs-button *, .cs-button:visited * {
      color: #fff;
      fill: #fff; }
  .cs-button:hover:not([disabled]), .cs-button:focus:not([disabled]), .cs-button:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #346097; }
    .cs-button:hover:not([disabled]) *, .cs-button:focus:not([disabled]) *, .cs-button:active:not([disabled]) * {
      color: #fff;
      fill: #fff; }
  .cs-button[disabled] {
    opacity: 0.65;
    cursor: auto; }
  .cs-button__span {
    margin-top: 0;
    z-index: 1;
    font-weight: 700;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    padding-left: 0;
    padding-right: 4em; }
  .cs-button__icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-right: 0;
    right: 1em; }

.cs-button-component {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: none;
  padding: 0.75em 1em;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5;
  margin: 0 0 3rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }
  .cs-button-component:hover, .cs-button-component:focus, .cs-button-component:active {
    background-color: #78b0e0 !important; }
  .cs-button-component:before, .cs-button-component:after {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px; }
  .cs-button-component:before {
    width: 5.5rem;
    border-radius: 0 0px 0px 0;
    z-index: -1;
    background: linear-gradient(100deg, transparent 0%, transparent 15%, #346097 15%, #346097 100%); }
  .cs-button-component:after {
    width: 4rem;
    min-width: auto;
    background-color: #346097;
    transition: width 0.25s linear;
    z-index: -2;
    border-radius: 0px; }
  .cs-button-component:hover:after {
    width: calc(100% + 2px); }
  .cs-button-component::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .cs-button-component, .cs-button-component:visited {
    cursor: pointer;
    border-radius: 0px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #78b0e0; }
    .cs-button-component *, .cs-button-component:visited * {
      color: #fff;
      fill: #fff; }
  .cs-button-component:hover:not([disabled]), .cs-button-component:focus:not([disabled]), .cs-button-component:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #346097; }
    .cs-button-component:hover:not([disabled]) *, .cs-button-component:focus:not([disabled]) *, .cs-button-component:active:not([disabled]) * {
      color: #fff;
      fill: #fff; }
  .cs-button-component[disabled] {
    opacity: 0.65;
    cursor: auto; }
  @media (min-width: 48em) {
    .cs-button-component {
      margin: 0 0 4rem; } }
  .cs-button-component--skip-spacing {
    margin: 0; }
  .cs-button-component__span {
    margin-top: 0;
    z-index: 1;
    font-weight: 700;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    padding-left: 0;
    padding-right: 4em; }
  .cs-button-component__icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-right: 0;
    right: 1em; }
  .cs-button-component__amount:before {
    content: '('; }
  .cs-button-component__amount:after {
    content: ')'; }
