/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
.cs-topbar {
  background: transparent;
  display: none;
  width: 100%;
  font-size: 1.1rem; }
  @media (min-width: 64em) {
    .cs-topbar {
      display: flex;
      z-index: 302; } }
  .cs-topbar__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    height: 2.7rem; }
    @media (min-width: 64em) {
      .cs-topbar__wrapper {
        flex-wrap: nowrap; } }
  .cs-topbar__item {
    color: #484848;
    display: flex;
    flex: 0 0 auto;
    white-space: nowrap;
    height: 100%; }
    .cs-topbar__item--usps {
      margin-right: auto;
      display: none; }
      @media (min-width: 80em) {
        .cs-topbar__item--usps {
          display: flex; } }
    .cs-topbar__item--hotline {
      align-items: center;
      flex: 1;
      justify-content: flex-end; }
    .cs-topbar__item--language {
      height: 100%; }
  .cs-topbar__list,
  .cs-topbar__item > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .cs-topbar__list-item {
    margin-bottom: 0;
    height: 2.7rem;
    display: flex;
    align-items: center;
    position: relative; }
    .cs-topbar__list-item:not(:last-child) {
      padding-right: 1.3rem; }
    .cs-topbar__list-item:not(:last-child) {
      margin-right: 1.3rem; }
      .cs-topbar__list-item:not(:last-child):before {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        content: '';
        border-right: 0.2rem solid #fff; }
  .cs-topbar__text {
    color: #484848; }
  .cs-topbar__link {
    margin-right: 0.9rem; }
    .cs-topbar__link, .cs-topbar__link:visited {
      color: #78b0e0;
      text-decoration: none; }
    .cs-topbar__link:hover, .cs-topbar__link:focus, .cs-topbar__link:active, .cs-topbar__link:visited:hover, .cs-topbar__link:visited:focus {
      color: #346097;
      text-decoration: none; }
  .cs-topbar__hotline {
    display: flex;
    align-items: center; }
  .cs-topbar__phone-number {
    font-weight: 700;
    margin-right: 0.9rem; }
    .cs-topbar__phone-number, .cs-topbar__phone-number:visited {
      color: #000;
      text-decoration: none; }
    .cs-topbar__phone-number:hover, .cs-topbar__phone-number:focus, .cs-topbar__phone-number:active, .cs-topbar__phone-number:visited:hover, .cs-topbar__phone-number:visited:focus {
      color: #000;
      text-decoration: none; }

.cs-container--top-bar {
  background-color: #f0f0f0;
  max-width: none; }
