/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
#tab-documentation {
  display: none; }

.tuplex-tree {
  position: relative;
  padding: 0 0 1rem 2rem;
  border-bottom: 1px solid #dcdcdc; }
  .tuplex-tree .description {
    position: relative;
    display: none; }
    .tuplex-tree .description .level-1 {
      margin-left: 1rem; }
    .tuplex-tree .description .level-2,
    .tuplex-tree .description .level-3 {
      margin-left: 2rem; }
  .tuplex-tree .description.active {
    display: block; }
  .tuplex-tree .widget.am-attachments {
    padding-left: 2.5rem; }
    .tuplex-tree .widget.am-attachments .am-fileline {
      position: relative; }
      .tuplex-tree .widget.am-attachments .am-fileline:after {
        content: "";
        display: block;
        width: 13px;
        height: 0;
        border-top: 1px solid;
        margin-top: -1px;
        position: absolute;
        top: 50%;
        left: -18px; }
  .tuplex-tree .title {
    display: flex;
    position: relative;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: fit-content;
    cursor: pointer; }
    .tuplex-tree .title:after {
      content: "\f0fe";
      font-family: "Font Awesome 5 Pro";
      width: 13px;
      height: 0;
      font-size: 14px;
      font-weight: 300;
      position: absolute;
      top: 0;
      left: -18px;
      height: 100%;
      display: flex;
      align-items: center;
      background: #fff; }
  .tuplex-tree .title.active:after {
    content: "\f146"; }
  .tuplex-tree .am-fileicon {
    width: 24px; }

.accordeon:before,
.description:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 7px;
  margin-right: 1rem;
  border-left: 1px solid; }
