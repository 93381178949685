//////
@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style.scss';
@import 'components/indicators/mixins.scss';
@import 'components/button/mixin.scss';
@import 'components/price-box/variables.scss'; // Some globals

.page-layout-e-commerce {
  .availability-legend {
    display: none;
  }

  .link_to_locator {
    display: none;
  }

  .rows {
    .netto {
      display: block;
      font-size: 11px;

      &::before {
        content: 'Netto';
      }
    }

    .brutto {
      display: block;
      font-size: 11px;

      &::before {
        content: close-quote !important;
      }
    }
  }
}

.catalog-product-view {
  .cs-addtocart__button {
    padding: 17px 54px 17px 10px !important;
  }

  .messages {
    max-width: $page_max-width;
    margin: 0 auto;
    position: relative;
    top: -15px;
  }

  .rows {
    .kup-on-line {
      text-align: center;
    }

    .brutto {
      display: block;
      font-size: 11px;

      &::before {
        content: 'Brutto';
      }
    }
  }

  .cs-product-details-nav {
    display: none !important;
  }

  .price-box {
    display: none !important;
  }

  .product-info-price {
    margin-top: 2rem;

    .additional-attributes-wrapper {
      margin-bottom: 0;
    }
  }
}

.button-parent-ecomm {
  position: relative;
  top: 20px;
  padding: 5px 15px 5px 15px;
  border: 2px solid $color_crimson;
  font-weight: 700;
  text-transform: uppercase;
  color: $color_crimson;

  &.oddzialy {
    border: 2px $color-tuplex-yellow solid;
    background: $color-tuplex-yellow;
    color: $color_black;
  }
}

.cs-product-details__content .link_to_locator {
  margin: 20px 0px 0px 0px !important;
  text-align: center;
  font-size: 18px;

  @include media('<=tablet') {
    margin: 20px 0px !important;
    ;
  }
}

.link_to_locator {
  margin: 20px 0px 0px 0px !important;
  font-size: 18px;

  @include media('<=tablet') {
    margin: 20px 0px !important;
    ;
  }
}

.delivery-legend {
  max-width: $page_max-width;
  margin: 0 auto;

  ul {
    list-style-type: none;
    padding-left: 10px;

    li {
      &::before {
        font-family: "Font Awesome 5 Pro";
        padding-right: 10px;
        font-size: 18px;
      }
    }
  }
}

.kurier-dostawa-standardowa {
  font-style: inherit;

  &::before {
    content: "\f467";
    font-family: "Font Awesome 5 Pro";
  }
}

.kurier-specjalne-pakowanie {
  font-style: inherit;

  &::before {
    content: "\f4de";
    font-family: "Font Awesome 5 Pro";
  }
}

.odbior-osobisty {
  font-style: inherit;

  &::before {
    content: "\f5a0";
    font-family: "Font Awesome 5 Pro";
  }
}

.col.item {

  .kurier-dostawa-standardowa,
  .kurier-specjalne-pakowanie,
  .odbior-osobisty {
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
  }
}

.availability-legend {
  max-width: $page_max-width;
  margin: 0 auto;

  ul {
    list-style-type: none;
    padding-left: 10px;

    li {
      &::before {
        padding-right: 10px;
      }
    }
  }
}

.dostepne-w-oddziale {
  font-style: inherit;

  &::before {
    content: "\f3c5";
    font-family: "Font Awesome 5 Pro";
    font-size: 18px;
    font-weight: 300;
  }
}

.dostepne-on-line {
  font-style: inherit;
  display: flex;

  &::before {
    content: url(/cart-online.svg);
    width: 27px;
    display: block;
    height: 27px;
  }
}

.kup-on-line {
  padding-top: 17px !important;

  .redingo-on-line {
    span {
      &::before {
        content: url("../images/icons/white-cart.svg");
        display: block;
        width: 27px;
        background: #e41f12;
        padding: 5px;
        border-radius: 2px;
        margin: 0 auto;

      }
    }
  }

  div {
    display: flex;
    justify-content: space-evenly;

    a {
      display: flex;
      width: 100%;
      justify-content: center;

      .dostepne-w-oddziale {
        &::before {
          font-size: 18px;
          color: $color_black;
        }
      }
    }

    .redingo-oddziale {
      max-width: 50px;
      padding: 8px;
    }

    .redingo-on-line {
      padding: 8px;
      max-width: 50px;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #f0f0f0;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    top: 35px;
    position: absolute;
    z-index: 1;
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}

.page-layout-e-commerce {
  .tooltiptext {
    left: -60px;
  }
}

.catalog-product-view .cs-product-details__content-constructor {
  padding-top: 2rem;
}

.catalog-product-view .cs-product-details__content-constructor>.cs-container>.cs-container__inner {
  border-top: 1px solid #dcdcdc;
  padding-top: 2rem;
}

// product virtual

.column.main>.cs-container.cs-container--page-pdp-top>.product.attribute.description {
  max-width: $page_max-width;
  margin: 0 auto;

  &::before {
    display: none;
  }

  h4 {
    text-transform: none;
    font-size: 2rem;
  }
}

.column.main>.cs-container.cs-container--page-pdp-top>.product.attribute.overview {
  max-width: $page_max-width;
  margin: 0 auto;
}

.column.main>.cs-container.cs-container--page-pdp-top>.cs-page-product__buybox {
  margin: 0 auto;

  .product-info-main.cs-buybox {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cs-buybox__addtocart-button {
      width: auto;
      padding: 9px 40px 9px 10px !important;

      strong {
        color: #cb1c0e;
      }
    }

    .cs-buybox__addtocart-button:hover {
      strong {
        color: white;
      }
    }

  }
}

.column.main>.cs-container.cs-container--page-pdp-top>.cs-container__inner>.cs-page-product__top>.cs-page-product__gallery {
  width: 50% !important;
}

.column.main>.cs-container.cs-container--page-pdp-top>.cs-container__inner>.cs-page-product__top>.gallery-description {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: whitesmoke;


  p {
    text-align: center;
    text-transform: uppercase;
    font-size: 2.5rem;
    margin-bottom: 0px;
  }

  h2 {
    margin: 0.5rem 0;
    font-size: 3.8rem;
  }

  h3 {
    margin: 0.5rem 0;
    font-size: 3.2rem;
    margin-bottom: 3rem;
  }
}