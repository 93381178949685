@import 'config/variables';
@import 'vendors/include-media';
@import 'components/typography/mixin';

$authorization-link_item-border: 0.2rem solid $color_border-100 !default;
$authorization-link_color: $color_primary-500 !default;
$authorization-link_color-hover: $color_primary-800 !default;
$authorization-link_decoration: none !default;
$authorization-link_hover-decoration: $authorization-link_decoration !default;
$authorization-link_icon-color: $color_text-400 !default;
$authorization-link_icon-color-hover: $color_text-400 !default;

.#{$ns}authorization-link {
    $root: &;

    display: flex;
    align-items: center;
    position: relative;
    margin-left: 0.9rem;
    padding: 0 0 0 0.3rem;

    @include media('>=laptop') {
        padding: 0 0 0 1.3rem;
        margin-left: 1.7rem;
    }

    &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        content: '';
        border-left: $authorization-link_item-border;
    }

    &__link {
        @include link(
            $color: $authorization-link_color,
            $color-hover: $authorization-link_color-hover,
            $decoration: $authorization-link_decoration,
            $decoration-hover: $authorization-link_hover-decoration
        );

        display: flex;
        align-items: center;

        &:hover {
            #{$root}__icon {
                path {
                    fill: $authorization-link_icon-color-hover;
                }
            }
        }
    }

    &__icon {
        width: 1.8rem;
        height: 1rem;
        padding-right: 0.5rem;

        path {
            fill: $authorization-link_icon-color;
        }
    }
}
