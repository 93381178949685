@import 'config/variables';
@import 'vendors/include-media';


.categorypath-aktualnosci-folie-avery-500-ef-w-najlepszych-cenach-na-rynku, .category-idealne-w-aranzacji-wnetrz-plyty-fundermax-hpl-interior, .category-10-tys-przylbic-dla-polskich-szpitali, .category-oslony-z-plexi-pet-poliweglanu-idealne-rozwiazanie-w-miejscach-obslugi-klientow {

    h2 {
      margin: 3rem 0 3rem;
    }

    h3 {
      margin: 3rem 0 3rem;
      &:before {
        content: "\f672";
        color: $color-tuplex-yellow;
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        display: inline-block;
        margin-left: 0em;
        width: 50px;
        height: 50px;
        font-size: 24px;
        padding: 15px 10px;
        background: $color-tuplex-dark-blue;
        border-radius: 50%;
        margin-right: 20px;
      }
    }



    table {
      margin-bottom: 2rem;
      border: 1px solid $color-tuplex-text-darken;
      font-weight: 900;
      background: $color-tuplex-dark-blue;
      color: $color_white;
    }

    th, td {
      padding: 15px;
      text-align: left;
      border: 1px solid $color_white;

      p {
    margin: 0;
      }
    }

    th {
      background-color: $color-tuplex-dark-blue;
      color: $color_white;
      }

    font-size: 1.6rem;

    p {
      text-align: justify;
      img {
        margin-top: 20px;
        width: 100%;
      }
    }


    #button {
      margin-top: 2rem;
    }


    .cs-button-component__span {

      min-width: 250px;
      font-size: 20px;
    }

    .cs-paragraph {

      img {
        width: 100%;
      }

      @include media('>=tablet') {
      margin: 0 0 0rem;
      }

      ul {
        margin-top: 2rem;
        list-style: none;
        padding: 3rem 3rem 3rem 6rem;
        background-color: $color_gallery;
      }

      ul > li:before {
        content: "\f00c";
        color: $color-tuplex-green;
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        display: inline-block;
        margin-left: -1.9em;
        width: 1.9em;
      }
    }
  }
