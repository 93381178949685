/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
.category-tuplex-international .cs-image-teaser__slide {
  margin-right: 0;
  margin-bottom: 0 !important;
  width: 50% !important; }

.category-tuplex-international .cs-image-teaser__description {
  margin: 0;
  font-size: 0.8em;
  padding: 0 100px;
  letter-spacing: -0.18px;
  line-height: 1.3;
  color: inherit; }

.category-tuplex-international .cs-image-teaser__slogan {
  font-size: 1.1em;
  margin-bottom: 10px; }

.category-tuplex-international .cs-page-category__cc-top {
  margin: 0 auto; }

.cms-kariera .cs-image-teaser__slide {
  margin-right: 0;
  margin-bottom: 0 !important;
  width: 50% !important; }

.cms-kariera .cs-page-category__cc-top {
  max-width: 130rem;
  margin: 0 auto; }
