@import 'config/variables';
@import 'utils/visually-hidden';

.#{$ns}visually-hidden {
    @include visually-hidden;
}

.cs-header__logo {
    .logo {
        display: flex !important;
    }
}

// tmp grouped packages view table

.table.data.grouped {

    .rows.tier-price.row-zero {
        height: unset;
    }

    .tbody.closed>tr.tier-price:not(.row-zero) {
        display: none !important;
    }

    .tbody>tr.tier-price.row-zero .ecommerce-tuplex {
        display: none;
    }


    .tbody.closed>tr.tier-price.row-zero .ecommerce-tuplex {
        display: table-cell;
    }

    .rows.tier-price.summary.qty .cs-qty-increment__input {
        border: none;
        opacity: 1 !important;
        font-weight: 700;
    }

    .unfold-tiers {
        color: red;
        cursor: pointer;
        font-weight: 700;
    }

    .rows.tier-price.summary.qty {
        * {
            font-weight: 700;
        }

        .qty.summary-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .control.qty.summary {
                width: 50px;
                position: relative;

                .cs-qty-increment>input {
                    background: transparent;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 55px;
                    height: 55px;
                    left: 0px;
                    top: 0px;
                }
            }

            .qty-step-brutto {
                width: 70px;
            }

            .brutto-label {
                margin-right: 10px;
            }
        }
    }

    .rows.tier-price .col.item.ecommerce-tuplex .row-brutto {
        color: red;
        font-size: 1.2rem;
    }

    .rows.tier-price .col.item.ecommerce-tuplex .row-paczka {
        font-weight: 700;
    }

    .rows.tier-price.summary.qty .col.item.sumary-label .matching-qty-step {
        font-weight: 400;
    }

    .rows.tier-price.summary.qty .col.item.sumary-label .matching-qty-step-brutto {
        color: red;
        font-size: 1.2rem;
        font-weight: 400;
    }
}

// style 31.05.2023
.table.data.grouped>tbody:nth-child(even) {
    background-color: #f5f5f5;

    .qty.summary-wrapper-placeholder {
        background: #f5f5f5;
    }
}

.table.data.grouped {

    .cs-qty-increment__button--decrement,
    .cs-qty-increment__button--increment {
        background: white;
    }
}

.table.data.grouped>tbody.tier-price {
    border-collapse: collapse;
}

.table.data.grouped>tbody.tier-price td {
    border: 1px solid #d8d8d8;
}

.table.data.grouped>tbody.tier-price:first-child>tr>td:first-child {
    border-top: 0 !important;
}

.table.data.grouped>tbody.tier-price:not(.closed)>tr:first-child td {
    border-top: 0;
}

.table.data.grouped>tbody.tier-price>tr:nth-child(2) td {
    border-top: 0;
}

.table.data.grouped>tbody.tier-price>tr>td:first-child {
    border-left: 0;
}

.table.data.grouped>tbody.tier-price>tr:last-child td {
    border-bottom: 0;
}

.table.data.grouped>tbody.tier-price>tr>td:last-child {
    border-right: 0;
}

.table.data.grouped>thead {
    border-bottom: 0 !important;
}

.table.data.grouped tr>.col.qty.ilosc.ecommerce-tuplex.tiers {
    padding: 15px;
}

.cs-cart-summary__totals .data.table.totals>tbody {
    display: flex;
    flex-direction: column;
}

.cs-cart-summary__totals .data.table.totals>tbody>tr {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cs-cart-summary__totals .data.table.totals>tbody>.totals-tax {
    order: 99;
}

.cs-cart-summary__totals .data.table.totals>tbody>.grand.totals.excl {
    order: 98;
}

.cs-cart-summary__totals .data.table.totals>tbody>.grand.totals.incl {
    order: 100;
}

.col.item.wartosc.ecommerce-tuplex,
.col.item.ecommerce-tuplex.sztuka {
    &::after {
        content: none !important;
    }

    .netto {
        font-size: 1.4rem;
        color: #484848;
        font-weight: 400;

        &::before {
            content: none;
        }
    }

    .brutto {
        color: red;
        font-size: 1.2rem !important;
        font-weight: 400;

        &::before {
            content: none;
        }
    }
}

.rows.summary.qty>.col.item.wartosc {
    &::after {
        content: none !important;
    }

    .netto {
        font-size: 1.4rem;
        color: #484848;
        font-weight: 400;

        &::before {
            content: none;
        }
    }

    .brutto {
        color: red;
        font-size: 1.2rem;
        font-weight: 400;

        &::before {
            content: none;
        }
    }
}

.redingo-on-line.catalog-link-to-shop>span::before {
    width: 20px !important;
    height: 20px !important;
}

// label na menu, sklep internetowy
nav.cs-navigation>.cs-navigation__list--main>.cs-navigation__item.cs-navigation__item--main[data-category-id|="556"] {
    .cs-navigation__link--main {
        text-decoration: none;
    }

    .cs-navigation__link--main::before {
        content: url("../images/icons/red-cart.svg");
        display: block;
        width: 19px;
        height: auto;
        color: white;
        margin-right: 8px;
        text-decoration: none !important;

        &:hover {
            text-decoration: none !important;
        }
    }
}

// always show cart qty counter label

.counter.qty.empty.cs-header-user-nav__qty-counter.cs-header-user-nav__qty-counter--minicart.cs-addtocart__minicart-qty-badge.cs-header-user-nav__qty-counter--empty {
    opacity: 1 !important;
}

.counter.qty.empty.cs-header-user-nav__qty-counter.cs-header-user-nav__qty-counter--minicart .cs-header-user-nav__qty-counter-span {
    min-width: unset !important;
}

// category online price button
.cs-product-tile__details.product-item-details .divTable>.divTableBody .divTableCell.ecommerce-tuplex_ .redingo-on-line.catalog-link-to-shop {
    display: flex;
    align-items: center;
    background: #e41f12;
    width: fit-content;
    font-weight: 700;
    padding: 5px;
    border-radius: 2px;
    color: white;
    margin: 0 auto;

    .online-price {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.cs-product-tile__container .cs-product-tile__main .divTable .divTableBody>.divTableRow:hover {
    .divTableCell {
        // filter: brightness(0.9);
        background: #dddddd;
    }
}


// tuplex international styles
.category-tuplex-international {
    .cs-container.cs-container--image-teaser.cs-container--image-teaser-window-width.cs-container--teaser-and-text {
        margin-bottom: 0;

        .cs-image-teaser__slides.cs-image-teaser__slides.cs-image-teaser__slides--mobile_image-text {
            gap: 0 !important;

            .cs-image-teaser__content p.cs-image-teaser__slogan {
                font-size: 32px !important;
                font-weight: 700 !important;
            }

            .cs-image-teaser__content p.cs-image-teaser__description {
                font-size: 23px !important;
            }

            .cs-image-teaser__content .cs-image-teaser__cta span.cs-image-teaser__cta-span {
                font-size: 16px !important;
            }

        }
    }
}

// kim jesteśmy
.category-kim-jestesmy {
    .cs-container.cs-container--image-teaser.cs-container--teaser-and-text {
        margin-bottom: 0;

        .cs-image-teaser__content p.cs-image-teaser__slogan {
            font-size: 32px !important;
            font-weight: 700 !important;
        }

        .cs-image-teaser__slides.cs-image-teaser__slides.cs-image-teaser__slides--mobile_image-text {
            gap: 0 !important;
        }

        .cs-image-teaser__content .cs-image-teaser__cta span.cs-image-teaser__cta-button-span {
            font-size: 16px !important;
        }

        .cs-image-teaser__content .cs-image-teaser__cta span.cs-image-teaser__cta-span {
            font-size: 16px !important;
        }
    }
}