/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.cs-image-teaser__slide--has-video-teaser .cs-image-teaser__picture {
  position: relative; }

.cs-image-teaser__slide--has-video-teaser .cs-image-teaser__content,
.cs-image-teaser__slide--has-video-teaser .cs-image-teaser__overlay,
.cs-image-teaser__slide--has-video-teaser .cs-image-teaser__gradient,
.cs-image-teaser__slide--has-video-teaser .cs-image-teaser__optimizer-container {
  z-index: 1; }

.cs-image-teaser [data-video-teaser] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1; }
  .cs-image-teaser [data-video-teaser]:empty {
    display: none; }

.cs-image-teaser [id^='video-teaser'] {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; }
