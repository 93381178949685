@import 'config/colors';
@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';
@import 'etc/view.scss';
@import 'utils/deep-get/deep-get.scss';




.promo-infobanner {
  text-align: center;
  background: $color_thunderbird;
  padding: 10px;
  margin: 0 0 10px;

  a {
    color: white;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 300;

      .value-promobanner_header {
        font-weight: 700 !important;
      }
      .sprawdz-promobanner_header {
        font-weight: 400 !important;;
        background: white;
        color: $color_thunderbird;
        padding: 0 10px;
        margin-left: 10px;
        border-radius: 5px;
      }


    &:hover {
      color: white;
    }
  }
}

.promo-infobanner-green {
  text-align: center;
  background: $color-eco-tuplex;
  padding: 10px;
  margin: 0 0 10px;

  a {
    color: white;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 300;
    @include media('<tablet') {
      font-size: 20px;
    }
    .value-promobanner_header {
      font-weight: 700 !important;
    }
    .sprawdz-promobanner_header {
      font-weight: 400 !important;;
      background: white;
      color: $color-eco-tuplex;
      padding: 0 10px;
      margin-left: 10px;
      border-radius: 5px;
    }


    &:hover {
      color: white;
    }
  }
}

.header__banner_promobanner {

  @include media('<tablet') {
  margin-top: 0px;
  }
}
