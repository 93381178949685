/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/*
 * Because price-box is used in multiple components like tile, buybox etc,
 * we need to move variables to separate file to have possibility to include them
 * in each component without generate price-box component styles multiple times
*/
.page-layout-e-commerce .availability-legend {
  display: none; }

.page-layout-e-commerce .link_to_locator {
  display: none; }

.page-layout-e-commerce .rows .netto {
  display: block;
  font-size: 11px; }
  .page-layout-e-commerce .rows .netto::before {
    content: 'Netto'; }

.page-layout-e-commerce .rows .brutto {
  display: block;
  font-size: 11px; }
  .page-layout-e-commerce .rows .brutto::before {
    content: close-quote !important; }

.catalog-product-view .cs-addtocart__button {
  padding: 17px 54px 17px 10px !important; }

.catalog-product-view .messages {
  max-width: 130rem;
  margin: 0 auto;
  position: relative;
  top: -15px; }

.catalog-product-view .rows .kup-on-line {
  text-align: center; }

.catalog-product-view .rows .brutto {
  display: block;
  font-size: 11px; }
  .catalog-product-view .rows .brutto::before {
    content: 'Brutto'; }

.catalog-product-view .cs-product-details-nav {
  display: none !important; }

.catalog-product-view .price-box {
  display: none !important; }

.catalog-product-view .product-info-price {
  margin-top: 2rem; }
  .catalog-product-view .product-info-price .additional-attributes-wrapper {
    margin-bottom: 0; }

.button-parent-ecomm {
  position: relative;
  top: 20px;
  padding: 5px 15px 5px 15px;
  border: 2px solid #e41f12;
  font-weight: 700;
  text-transform: uppercase;
  color: #e41f12; }
  .button-parent-ecomm.oddzialy {
    border: 2px #FFEF45 solid;
    background: #FFEF45;
    color: #000; }

.cs-product-details__content .link_to_locator {
  margin: 20px 0px 0px 0px !important;
  text-align: center;
  font-size: 18px; }
  @media (max-width: 48em) {
    .cs-product-details__content .link_to_locator {
      margin: 20px 0px !important; } }

.link_to_locator {
  margin: 20px 0px 0px 0px !important;
  font-size: 18px; }
  @media (max-width: 48em) {
    .link_to_locator {
      margin: 20px 0px !important; } }

.delivery-legend {
  max-width: 130rem;
  margin: 0 auto; }
  .delivery-legend ul {
    list-style-type: none;
    padding-left: 10px; }
    .delivery-legend ul li::before {
      font-family: "Font Awesome 5 Pro";
      padding-right: 10px;
      font-size: 18px; }

.kurier-dostawa-standardowa {
  font-style: inherit; }
  .kurier-dostawa-standardowa::before {
    content: "\f467";
    font-family: "Font Awesome 5 Pro"; }

.kurier-specjalne-pakowanie {
  font-style: inherit; }
  .kurier-specjalne-pakowanie::before {
    content: "\f4de";
    font-family: "Font Awesome 5 Pro"; }

.odbior-osobisty {
  font-style: inherit; }
  .odbior-osobisty::before {
    content: "\f5a0";
    font-family: "Font Awesome 5 Pro"; }

.col.item .kurier-dostawa-standardowa,
.col.item .kurier-specjalne-pakowanie,
.col.item .odbior-osobisty {
  cursor: pointer;
  font-size: 18px;
  font-weight: 300; }

.availability-legend {
  max-width: 130rem;
  margin: 0 auto; }
  .availability-legend ul {
    list-style-type: none;
    padding-left: 10px; }
    .availability-legend ul li::before {
      padding-right: 10px; }

.dostepne-w-oddziale {
  font-style: inherit; }
  .dostepne-w-oddziale::before {
    content: "\f3c5";
    font-family: "Font Awesome 5 Pro";
    font-size: 18px;
    font-weight: 300; }

.dostepne-on-line {
  font-style: inherit;
  display: flex; }
  .dostepne-on-line::before {
    content: url(/cart-online.svg);
    width: 27px;
    display: block;
    height: 27px; }

.kup-on-line {
  padding-top: 17px !important; }
  .kup-on-line .redingo-on-line span::before {
    content: url("../images/icons/white-cart.svg");
    display: block;
    width: 27px;
    background: #e41f12;
    padding: 5px;
    border-radius: 2px;
    margin: 0 auto; }
  .kup-on-line div {
    display: flex;
    justify-content: space-evenly; }
    .kup-on-line div a {
      display: flex;
      width: 100%;
      justify-content: center; }
      .kup-on-line div a .dostepne-w-oddziale::before {
        font-size: 18px;
        color: #000; }
    .kup-on-line div .redingo-oddziale {
      max-width: 50px;
      padding: 8px; }
    .kup-on-line div .redingo-on-line {
      padding: 8px;
      max-width: 50px; }

.tooltip {
  position: relative;
  display: inline-block; }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #f0f0f0;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    top: 35px;
    position: absolute;
    z-index: 1; }
  .tooltip:hover .tooltiptext {
    visibility: visible; }

.page-layout-e-commerce .tooltiptext {
  left: -60px; }

.catalog-product-view .cs-product-details__content-constructor {
  padding-top: 2rem; }

.catalog-product-view .cs-product-details__content-constructor > .cs-container > .cs-container__inner {
  border-top: 1px solid #dcdcdc;
  padding-top: 2rem; }

.column.main > .cs-container.cs-container--page-pdp-top > .product.attribute.description {
  max-width: 130rem;
  margin: 0 auto; }
  .column.main > .cs-container.cs-container--page-pdp-top > .product.attribute.description::before {
    display: none; }
  .column.main > .cs-container.cs-container--page-pdp-top > .product.attribute.description h4 {
    text-transform: none;
    font-size: 2rem; }

.column.main > .cs-container.cs-container--page-pdp-top > .product.attribute.overview {
  max-width: 130rem;
  margin: 0 auto; }

.column.main > .cs-container.cs-container--page-pdp-top > .cs-page-product__buybox {
  margin: 0 auto; }
  .column.main > .cs-container.cs-container--page-pdp-top > .cs-page-product__buybox .product-info-main.cs-buybox {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .column.main > .cs-container.cs-container--page-pdp-top > .cs-page-product__buybox .product-info-main.cs-buybox .cs-buybox__addtocart-button {
      width: auto;
      padding: 9px 40px 9px 10px !important; }
      .column.main > .cs-container.cs-container--page-pdp-top > .cs-page-product__buybox .product-info-main.cs-buybox .cs-buybox__addtocart-button strong {
        color: #cb1c0e; }
    .column.main > .cs-container.cs-container--page-pdp-top > .cs-page-product__buybox .product-info-main.cs-buybox .cs-buybox__addtocart-button:hover strong {
      color: white; }

.column.main > .cs-container.cs-container--page-pdp-top > .cs-container__inner > .cs-page-product__top > .cs-page-product__gallery {
  width: 50% !important; }

.column.main > .cs-container.cs-container--page-pdp-top > .cs-container__inner > .cs-page-product__top > .gallery-description {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: whitesmoke; }
  .column.main > .cs-container.cs-container--page-pdp-top > .cs-container__inner > .cs-page-product__top > .gallery-description p {
    text-align: center;
    text-transform: uppercase;
    font-size: 2.5rem;
    margin-bottom: 0px; }
  .column.main > .cs-container.cs-container--page-pdp-top > .cs-container__inner > .cs-page-product__top > .gallery-description h2 {
    margin: 0.5rem 0;
    font-size: 3.8rem; }
  .column.main > .cs-container.cs-container--page-pdp-top > .cs-container__inner > .cs-page-product__top > .gallery-description h3 {
    margin: 0.5rem 0;
    font-size: 3.2rem;
    margin-bottom: 3rem; }
