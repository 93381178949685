/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
.cs-cms-block .lead {
  margin-bottom: 20px;
  font-size: 16px; }

.cs-cms-block .details {
  padding: 3rem 2rem 0 3rem;
  background-color: #f0f0f0; }

.cs-cms-block .column {
  display: inline-grid;
  padding-right: 10px;
  width: 49%; }
  @media (max-width: 64em) {
    .cs-cms-block .column {
      width: 100% !important; } }
  .cs-cms-block .column ul {
    padding: 0 0 0 2rem; }

.item.title {
  width: 100%; }
  .item.title .switch {
    width: 100%;
    color: #fff !important;
    background: #78b0e0 !important; }

.item.title:hover .switch {
  color: #78b0e0 !important;
  background: #fff !important; }

.item.title.active .switch {
  color: #78b0e0 !important;
  background: #fff !important; }

@media (min-width: 48em) {
  .tab3 {
    display: block;
    margin-bottom: 20px; }
    .tab3 .item.title {
      width: 33% !important; }
  .tab4 {
    display: block;
    margin-bottom: 20px; }
    .tab4 .item.title {
      width: 25% !important; }
  .product.data.items > .item.title {
    margin: 0 -1px 0 0 !important;
    float: left; }
    .product.data.items > .item.title a {
      font-weight: 600; }
    .product.data.items > .item.title .switch {
      font-size: 1.6rem !important;
      text-decoration: none;
      color: #fff;
      background: #78b0e0;
      border: 1px solid #78b0e0 !important;
      height: 20px !important;
      padding: 10px 0 !important;
      text-align: center;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      width: 100%;
      box-sizing: content-box; }
    .product.data.items > .item.title .active {
      color: #78b0e0 !important; } }

@media (min-width: 48em) {
  .product.data.items > .item.title.active > .switch,
  .product.data.items > .item.title.active > .switch:focus,
  .product.data.items > .item.title.active > .switch:hover {
    padding-bottom: 10px;
    color: #78b0e0 !important; } }

@media (min-width: 48em) {
  .product.data.items > .item.title.active > .switch,
  .product.data.items > .item.title.active > .switch:focus,
  .product.data.items > .item.title.active > .switch:hover,
  .product.data.items > .item.title:not(.disabled) > .switch:active {
    padding-bottom: 10px !important; } }

@media (min-width: 48em) {
  .product.data.items > .item.title > .switch:visited {
    color: #fff;
    text-decoration: none; } }

@media (min-width: 48em) {
  .product.data.items > .item.content {
    margin-top: 60px;
    padding: 0;
    border-top: 0;
    float: right;
    margin-left: -100%;
    width: 100%; } }

@media (min-width: 48em) {
  .product.data.items > .item.title:not(.disabled) > .switch:focus,
  .product.data.items > .item.title:not(.disabled) > .switch:hover {
    color: #78b0e0 !important; } }
