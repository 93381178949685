@import 'config/variables';

.page-layout-landing-category {
  .cs-page-category {
    padding-top: 0;
  }

  .category-view {
    position: relative;
  }

  .tuplex-lending-top {
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;

    .page-title__headline.cs-page-title__headline {
      color: $color_white;
    }

    .cs-cms-block.cs-page-category__description {
      h2 {
        color: $color_white;
      }
    }
  }

  .category-image {
    margin: 0;

    .image {
      filter: brightness(0.6);
      width: 100vw;
    }
  }
}