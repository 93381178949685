@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/component-spacing';
@import 'components/headline/mixin';

.#{$ns}headline {
    @include headline();

    margin: 0;
    padding: 0;
    margin: 4rem 0 4rem 0 !important;
    
    @include component-spacing();

    &__title {
        @include headline-title();
    }

    &__subtitle {
        @include headline-subtitle();
    }
}
