@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';
@import 'components/typography/mixin';

$footer-links_list-item-margin: 0 0 0.3em !default;

$footer-links_link-font-size: inherit !default;
$footer-links_link-color: $color_black !default;
$footer-links_link-hover-color: $footer-links_link-color !default;
$footer-links_link-decoration: none !default;
$footer-links_link-hover-decoration: underline !default;
$footer-links_link-padding-mobile: 0.5em 0 !default;
$footer-links_link-padding-desktop: 0.20em 0 !default;

.#{$ns}footer-links {
    $root: &;

    @include reset-list-style();

    &__item {
        margin: $footer-links_list-item-margin;
        .am-attachments .am-fileline .am-filelink {
            line-height: inherit;
            height: inherit;
        }
    }

    a {
        @include link(
            $color: $footer-links_link-color,
            $color-hover: $footer-links_link-hover-color,
            $decoration: $footer-links_link-decoration,
            $decoration-hover: $footer-links_link-hover-decoration
        );

        &,
        &:visited {
            display: block;
            padding: $footer-links_link-padding-mobile;
            font-size: $footer-links_link-font-size;

            @include media('>=tablet') {
                padding: $footer-links_link-padding-desktop;
                display: inline-block;
            }
        }
    }
}
