@import 'config/variables';
@import 'config/colors'; 

#tab-documentation {
  display: none;
}

.tuplex-tree {
  position: relative;
  padding: 0 0 1rem 2rem;
  border-bottom: 1px solid $color-tuplex-bg-grey;

  .description {
    position: relative;
    display: none;

    .level-1 {
      margin-left: 1rem;
    }

    .level-2,
    .level-3 {
      margin-left: 2rem;
    }
  }

  .description.active {
    display: block;
  }

  .widget.am-attachments {
    padding-left: 2.5rem;

    .am-fileline {
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 13px;
        height: 0;
        border-top: 1px solid;
        margin-top: -1px;
        position: absolute;
        top: 50%;
        left: -18px;
      }
    }
  }

  .title {
    display: flex;
    position: relative;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: fit-content;
    cursor: pointer;

    &:after {
      content: "\f0fe";
      font-family: "Font Awesome 5 Pro";
      width: 13px;
      height: 0;
      font-size: 14px;
      font-weight: 300;
      position: absolute;
      top: 0;
      left: -18px;
      height: 100%;
      display: flex;
      align-items: center;
      background: $color-white;
    }
  }

  .title.active {
    &:after {
      content: "\f146";
    }
  }

  .am-fileicon {
    width: 24px;
  }
}

.accordeon,
.description {
  &:before {
    content: "";
    display: block;
    width: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    // left: 0;
    left: 7px;
    margin-right: 1rem;
    border-left: 1px solid;
  }
}
