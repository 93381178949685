/**
 * Footer component customization.
 * @see /Magento_Theme/templates/footer/columns.phtml for templates.
 */
@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/visually-hidden';
@import 'components/pseudoicon/mixin';
@import 'components/typography/mixin';

$footer_text-color: $color_black !default;
$footer_background: $color-tuplex-bg-grey !default;
$footer_edge-gutter: $page_edge-gutter !default;
$footer_section-edge-gutter: $page_edge-gutter !default;
$footer_section-vertical-offset: 2em !default;
$footer_section-separator-border: 1px solid $color_gray !default;
$footer_section-title-headline-level: $font-size_headline-4 !default;
$footer_section-title-padding: 2rem 1.5rem !default;
$footer_section-title-color: $color-tuplex-text-normal  !default;
$footer_section-title-font-size: 2rem !default;
$footer_section-li-font-size: 1.3rem !default;
$footer_section-title-transform: uppercase !default;
$footer_section-pseudoicon-include: true !default;
$footer_promo-display-on-mobile: false !default;
$footer_logo-display: block !default;
$footer_logo-width: 13.5em !default;
$footer_logo-height: 2.3em !default;

.#{$ns}footer {
    $root: &;
    font-size: 1.3rem;
    color: $footer_text-color;
    background: lighten( $footer_background, 10% );
    overflow: hidden;
    padding: 0 0 1rem;

    @include media('>=tablet') {
        padding: 0 $footer_edge-gutter;
    }

    &__content {
        padding: 0;

        @include media('>=tablet') {
            display: flex;
            flex-wrap: wrap;
            padding-top: 4rem;
            padding-bottom: 4rem;
        }

        @include media('>=laptop') {
            flex-wrap: nowrap;
        }
    }

    &__item {
        @include media('>=laptop') {
            flex: 1;
        }

        &--promo {
            @if $footer_promo-display-on-mobile==false {
                @include media('<tablet') {
                    display: none;
                }
            }

            flex-basis: 100%;

            @include media('<laptop') {
                margin-bottom: 1.5rem;
            }

            @include media('>=laptop') {
                flex-basis: auto;
                flex-grow: 1;
            }
        }

        &--links {
            @include media('>=tablet') {
                display: flex;
                flex-basis: 100%;
                margin-top: $footer_section-vertical-offset;
            }

            @include media('>=tablet') {
                margin-top: 0;
            }

            @include media('>=laptop') {
                flex-basis: auto;
                flex-grow: 5;
            }
        }

        &--extras {
            @include media('>=tablet') {
                display: flex;
                flex-basis: 100%;
            }

            @include media('>=laptop') {
                display: block;
                // flex-basis: 25%;
                flex-basis: 10%;
                flex-grow: 2;
            }
        }
    }

    &__section {
        @include media('>=tablet') {
            flex: 1;

            & h2 {
            font-size: 2rem;
            }
        }

        &--newsletter {
            @include media('>=tablet') {
                flex-grow: 2;
            }
        }

        &--socials {
            @include media('>=laptop') {
                margin-top: 2rem;
            }
        }

        &--stores-switcher {
            @include media('>=laptop') {
                margin-top: 1rem;
                max-width: 20rem;
            }
        }
    }

    &__section-title,
    [data-role='title'] {
        @if $footer_section-title-headline-level !='none' {
            @include headline($level: $footer_section-title-headline-level);
        }

        position: relative;
        margin: 0;
        border-top: $footer_section-separator-border;
        padding: $footer_section-title-padding;
        color: $footer_section-title-color;
        font-size: $footer_section-title-font-size;
        font-weight: 700;
        text-transform: $footer_section-title-transform;
        letter-spacing: 0.08rem;

        @include media('<tablet') {
        font-size: 1.4rem;
        padding: 2rem ;
        }

        @include media('<tablet') {

            #{$root}__section[data-mage-init] &,
            #{$root}__section[data-collapsible] & {
                cursor: pointer;

                @if $footer_section-pseudoicon-include {
                    @include pseudoicon();
                }
            }

            #{$root}__section--socials & {
                @include visually-hidden();
            }
        }

        @include media('>=tablet') {
            border-top: none;
            padding-left: 0;
        }

        @include media('>=laptop') {
            padding-top: 0;
            padding-right: 0;
        }
    }

    &__section-content,
    [data-role='content'] {
        padding: 0 $footer_section-edge-gutter $footer_section-edge-gutter;
        font-size: $footer_section-li-font-size;

        @include media('<tablet') {

            #{$root}__section[data-mage-init] &,
            #{$root}__section[data-collapsible] & {
                display: none;
            }
        }

        @include media('>=tablet') {
            padding-left: 0;
        }

        @include media('>=laptop') {
            padding-right: 0;
            padding-bottom: 0;
        }
    }

    &__shop-logo {
        display: $footer_logo-display;
        width: $footer_logo-width;
        height: $footer_logo-height;
        min-width: $footer_logo-width;
        max-width: 100%;
        max-height: 100%;
    }
}

.cs-container {
    &--footer {
        margin-top: 4rem;
        max-width: none;
    }
}

.footer-copyright {
    margin: 0 auto;
    text-align: center;
    line-height: 1.9;
    padding-bottom: 20px;
    font-size: $footer_section-li-font-size;
    .nip {
        padding: 0px 0 0px 0;
    }
    .contact {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        max-width: 320px;
    }
}

.cs-footer-links__item .block-category-link.widget {
    display: block;
    margin-bottom: 2px;
}

.block-static-block.widget, .block-cms-link.widget {
    margin-bottom: 2px !important;
    font-size: 1.3rem !important;
}

.cs-footer__item--links .cs-footer__section:nth-child(2) {
    margin-top: 50px;
    @include media('<tablet') {
        margin-top: 0px;
    }
    .cs-footer__section-title {
    display: none;
    @include media('<tablet') {
        display: block;
    }
    }
}

.cs-footer__item.cs-footer__item--extras {
    display: flex;
    flex-direction: column;
    & h2 {
        font-size: 2rem;
    }
    .cs-footer__section  {
        &:nth-child(2) {
            .cs-footer__section-title {
                padding-top: 2rem;
            }
        }
    }
    .footer-firma {
        order: -1;
        flex-grow: 0;
    }
    .cs-footer__section.cs-footer__section--newsletter {
        flex-grow: 0;
    }
}
.cs-footer__section.cs-footer__section--socials {
    h2 {
        @include media('<tablet') {
            padding-left: 2rem;
        }
    }
}
