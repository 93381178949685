@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';

.cs-cms-block {
  .lead {
    margin-bottom: 20px;
    font-size: 16px;
    //font-weight: 600;
    span {
      //font-weight: 700;
    }
  }

  .details {
    padding: 3rem 2rem 0 3rem;
    //display: flex;
    background-color: $color_gallery;
  }

  .column {
    display: inline-grid;
    padding-right: 10px;
    width: 49%;
    @include media('<=laptop') {
      //display: $toolbar_display;
      width: 100% !important;
    }

    ul {
      padding: 0 0 0 2rem;
      //padding-left: 0;
      //  list-style-type: none;
    }
  }

  h3 {
    // color: $color_black;
    // margin-top: 0;
    // margin-bottom: 15px;
    // font-size: 2.4rem;
  }
}

.item.title {
  width: 100%;

  .switch {
    width: 100%;
    color: $color_white !important;
    background: $color-tuplex-blue !important;
  }
}

.item.title:hover {
  .switch {
    color: $color-tuplex-blue !important;
    background: $color-white !important;
  }
}

.item.title.active {
  .switch {
    color: $color-tuplex-blue !important;
    background: $color-white !important;
  }
}
@include media('>=tablet') {
  .tab3 {
    display: block;
    margin-bottom: 20px;
    .item.title {
      width: 33% !important;
      
    }
  }

  .tab4 {
    display: block;
    margin-bottom: 20px;
    .item.title {
      width: 25% !important;
    }
  }

  .product.data.items > .item.title {
    margin: 0 -1px 0 0 !important;
    float: left;
    a{
      font-weight: 600;
    }
    & .switch {
      font-size: 1.6rem !important;
      text-decoration: none;
      color: $color_white;
      background: $color-tuplex-blue;
      border: 1px solid $color-tuplex-blue !important;
      height: 20px !important;
      padding: 10px 0 !important;
      text-align: center;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      width: 100%;
      box-sizing: content-box;
      // &:visited {
      //   color: $color_white !important;
      // }
    }

    & .active {
      color: $color-tuplex-blue !important;
    }
  }
}
@include media('>=tablet') {
  .product.data.items > .item.title.active > .switch,
  .product.data.items > .item.title.active > .switch:focus,
  .product.data.items > .item.title.active > .switch:hover {
    padding-bottom: 10px;
    color: $color-tuplex-blue !important;
  }
}
@include media('>=tablet') {
  .product.data.items > .item.title.active > .switch,
  .product.data.items > .item.title.active > .switch:focus,
  .product.data.items > .item.title.active > .switch:hover,
  .product.data.items > .item.title:not(.disabled) > .switch:active {
    padding-bottom: 10px !important;
  }
}
@include media('>=tablet') {
  .product.data.items > .item.title > .switch:visited {
    color: $color_white;
    text-decoration: none;
  }
}
@include media('>=tablet') {
  .product.data.items > .item.content {
    margin-top: 60px;
    padding: 0;
    border-top: 0;
    float: right;
    margin-left: -100%;
    width: 100%;
  }
}
@include media('>=tablet') {
  .product.data.items > .item.title:not(.disabled) > .switch:focus,
  .product.data.items > .item.title:not(.disabled) > .switch:hover {
    color: $color-tuplex-blue !important;
  }
}