@import 'config/variables';
@import 'vendors/include-media';
@import 'components/container/mixin';

.#{$ns}container {
    $root: &;

    @include container();
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;

    &__inner {
        @include container-wrapper();
        position: relative;

        &:before,
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }

    &__aside {
        width: 100%;

        @include media('>=tablet') {
            width: 40%;
        }

        @include media('>=laptop') {
            width: 32%;
        }

        @include media('>=desktop') {
            width: 30%;
        }
    }

    &__main-column {
        width: 56%;
        position: relative;

        @include media('>=laptop') {
            width: 65%;
        }

        @include media('>=desktop') {
            width: 67%;
        }

        @include media('<tablet') {
            max-width: 100% !important;
            width: 100% !important;
        }

        &--space-top {
            @include media('>=laptop') {
                padding-top: 3rem;
            }
        }
    }

    &--contrast {
        background-color: $color_gallery;

        @include media('>=laptop') {
            background-image: url('../images/background.png');
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    &--spacious {
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include media('>=laptop') {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }

    &--noscroll {
        overflow: hidden;
    }

    &--ontop {
        z-index: 301;
    }

    &--viewport-width {
        max-width: none;
    }
}
