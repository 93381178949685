@import 'config/variables';
@import 'vendors/include-media';

.cs-aftersearch-nav__filters{
    .cs-aftersearch-nav__filter.filter-attribute-kolor{
        .cs-aftersearch-nav__filter-title{
            @include media('>tablet') {
                display: none !important;
            }
        }
        .cs-aftersearch-nav__filter-content{
            .swatch-attribute.swatch-layered.kolor{
                padding: 1rem 0rem;
                .swatch-attribute-options{
                    justify-content: flex-start;
                    .swatch-option-link-layered{
                        min-width: 10rem;
                        .swatch-option.color{
                            // margin: 0px;
                            border: none;
                            &::after{
                                display: none;
                            }
                            &::before{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}