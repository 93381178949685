/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
.cs-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */ }

.cs-header__logo .logo {
  display: flex !important; }

.table.data.grouped .rows.tier-price.row-zero {
  height: unset; }

.table.data.grouped .tbody.closed > tr.tier-price:not(.row-zero) {
  display: none !important; }

.table.data.grouped .tbody > tr.tier-price.row-zero .ecommerce-tuplex {
  display: none; }

.table.data.grouped .tbody.closed > tr.tier-price.row-zero .ecommerce-tuplex {
  display: table-cell; }

.table.data.grouped .rows.tier-price.summary.qty .cs-qty-increment__input {
  border: none;
  opacity: 1 !important;
  font-weight: 700; }

.table.data.grouped .unfold-tiers {
  color: red;
  cursor: pointer;
  font-weight: 700; }

.table.data.grouped .rows.tier-price.summary.qty * {
  font-weight: 700; }

.table.data.grouped .rows.tier-price.summary.qty .qty.summary-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }
  .table.data.grouped .rows.tier-price.summary.qty .qty.summary-wrapper .control.qty.summary {
    width: 50px;
    position: relative; }
    .table.data.grouped .rows.tier-price.summary.qty .qty.summary-wrapper .control.qty.summary .cs-qty-increment > input {
      background: transparent; }
    .table.data.grouped .rows.tier-price.summary.qty .qty.summary-wrapper .control.qty.summary::after {
      content: '';
      position: absolute;
      width: 55px;
      height: 55px;
      left: 0px;
      top: 0px; }
  .table.data.grouped .rows.tier-price.summary.qty .qty.summary-wrapper .qty-step-brutto {
    width: 70px; }
  .table.data.grouped .rows.tier-price.summary.qty .qty.summary-wrapper .brutto-label {
    margin-right: 10px; }

.table.data.grouped .rows.tier-price .col.item.ecommerce-tuplex .row-brutto {
  color: red;
  font-size: 1.2rem; }

.table.data.grouped .rows.tier-price .col.item.ecommerce-tuplex .row-paczka {
  font-weight: 700; }

.table.data.grouped .rows.tier-price.summary.qty .col.item.sumary-label .matching-qty-step {
  font-weight: 400; }

.table.data.grouped .rows.tier-price.summary.qty .col.item.sumary-label .matching-qty-step-brutto {
  color: red;
  font-size: 1.2rem;
  font-weight: 400; }

.table.data.grouped > tbody:nth-child(even) {
  background-color: #f5f5f5; }
  .table.data.grouped > tbody:nth-child(even) .qty.summary-wrapper-placeholder {
    background: #f5f5f5; }

.table.data.grouped .cs-qty-increment__button--decrement,
.table.data.grouped .cs-qty-increment__button--increment {
  background: white; }

.table.data.grouped > tbody.tier-price {
  border-collapse: collapse; }

.table.data.grouped > tbody.tier-price td {
  border: 1px solid #d8d8d8; }

.table.data.grouped > tbody.tier-price:first-child > tr > td:first-child {
  border-top: 0 !important; }

.table.data.grouped > tbody.tier-price:not(.closed) > tr:first-child td {
  border-top: 0; }

.table.data.grouped > tbody.tier-price > tr:nth-child(2) td {
  border-top: 0; }

.table.data.grouped > tbody.tier-price > tr > td:first-child {
  border-left: 0; }

.table.data.grouped > tbody.tier-price > tr:last-child td {
  border-bottom: 0; }

.table.data.grouped > tbody.tier-price > tr > td:last-child {
  border-right: 0; }

.table.data.grouped > thead {
  border-bottom: 0 !important; }

.table.data.grouped tr > .col.qty.ilosc.ecommerce-tuplex.tiers {
  padding: 15px; }

.cs-cart-summary__totals .data.table.totals > tbody {
  display: flex;
  flex-direction: column; }

.cs-cart-summary__totals .data.table.totals > tbody > tr {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.cs-cart-summary__totals .data.table.totals > tbody > .totals-tax {
  order: 99; }

.cs-cart-summary__totals .data.table.totals > tbody > .grand.totals.excl {
  order: 98; }

.cs-cart-summary__totals .data.table.totals > tbody > .grand.totals.incl {
  order: 100; }

.col.item.wartosc.ecommerce-tuplex::after,
.col.item.ecommerce-tuplex.sztuka::after {
  content: none !important; }

.col.item.wartosc.ecommerce-tuplex .netto,
.col.item.ecommerce-tuplex.sztuka .netto {
  font-size: 1.4rem;
  color: #484848;
  font-weight: 400; }
  .col.item.wartosc.ecommerce-tuplex .netto::before,
  .col.item.ecommerce-tuplex.sztuka .netto::before {
    content: none; }

.col.item.wartosc.ecommerce-tuplex .brutto,
.col.item.ecommerce-tuplex.sztuka .brutto {
  color: red;
  font-size: 1.2rem !important;
  font-weight: 400; }
  .col.item.wartosc.ecommerce-tuplex .brutto::before,
  .col.item.ecommerce-tuplex.sztuka .brutto::before {
    content: none; }

.rows.summary.qty > .col.item.wartosc::after {
  content: none !important; }

.rows.summary.qty > .col.item.wartosc .netto {
  font-size: 1.4rem;
  color: #484848;
  font-weight: 400; }
  .rows.summary.qty > .col.item.wartosc .netto::before {
    content: none; }

.rows.summary.qty > .col.item.wartosc .brutto {
  color: red;
  font-size: 1.2rem;
  font-weight: 400; }
  .rows.summary.qty > .col.item.wartosc .brutto::before {
    content: none; }

.redingo-on-line.catalog-link-to-shop > span::before {
  width: 20px !important;
  height: 20px !important; }

nav.cs-navigation > .cs-navigation__list--main > .cs-navigation__item.cs-navigation__item--main[data-category-id|="556"] .cs-navigation__link--main {
  text-decoration: none; }

nav.cs-navigation > .cs-navigation__list--main > .cs-navigation__item.cs-navigation__item--main[data-category-id|="556"] .cs-navigation__link--main::before {
  content: url("../images/icons/red-cart.svg");
  display: block;
  width: 19px;
  height: auto;
  color: white;
  margin-right: 8px;
  text-decoration: none !important; }
  nav.cs-navigation > .cs-navigation__list--main > .cs-navigation__item.cs-navigation__item--main[data-category-id|="556"] .cs-navigation__link--main::before:hover {
    text-decoration: none !important; }

.counter.qty.empty.cs-header-user-nav__qty-counter.cs-header-user-nav__qty-counter--minicart.cs-addtocart__minicart-qty-badge.cs-header-user-nav__qty-counter--empty {
  opacity: 1 !important; }

.counter.qty.empty.cs-header-user-nav__qty-counter.cs-header-user-nav__qty-counter--minicart .cs-header-user-nav__qty-counter-span {
  min-width: unset !important; }

.cs-product-tile__details.product-item-details .divTable > .divTableBody .divTableCell.ecommerce-tuplex_ .redingo-on-line.catalog-link-to-shop {
  display: flex;
  align-items: center;
  background: #e41f12;
  width: fit-content;
  font-weight: 700;
  padding: 5px;
  border-radius: 2px;
  color: white;
  margin: 0 auto; }
  .cs-product-tile__details.product-item-details .divTable > .divTableBody .divTableCell.ecommerce-tuplex_ .redingo-on-line.catalog-link-to-shop .online-price {
    display: flex;
    justify-content: center;
    align-items: center; }

.cs-product-tile__container .cs-product-tile__main .divTable .divTableBody > .divTableRow:hover .divTableCell {
  background: #dddddd; }

.category-tuplex-international .cs-container.cs-container--image-teaser.cs-container--image-teaser-window-width.cs-container--teaser-and-text {
  margin-bottom: 0; }
  .category-tuplex-international .cs-container.cs-container--image-teaser.cs-container--image-teaser-window-width.cs-container--teaser-and-text .cs-image-teaser__slides.cs-image-teaser__slides.cs-image-teaser__slides--mobile_image-text {
    gap: 0 !important; }
    .category-tuplex-international .cs-container.cs-container--image-teaser.cs-container--image-teaser-window-width.cs-container--teaser-and-text .cs-image-teaser__slides.cs-image-teaser__slides.cs-image-teaser__slides--mobile_image-text .cs-image-teaser__content p.cs-image-teaser__slogan {
      font-size: 32px !important;
      font-weight: 700 !important; }
    .category-tuplex-international .cs-container.cs-container--image-teaser.cs-container--image-teaser-window-width.cs-container--teaser-and-text .cs-image-teaser__slides.cs-image-teaser__slides.cs-image-teaser__slides--mobile_image-text .cs-image-teaser__content p.cs-image-teaser__description {
      font-size: 23px !important; }
    .category-tuplex-international .cs-container.cs-container--image-teaser.cs-container--image-teaser-window-width.cs-container--teaser-and-text .cs-image-teaser__slides.cs-image-teaser__slides.cs-image-teaser__slides--mobile_image-text .cs-image-teaser__content .cs-image-teaser__cta span.cs-image-teaser__cta-span {
      font-size: 16px !important; }

.category-kim-jestesmy .cs-container.cs-container--image-teaser.cs-container--teaser-and-text {
  margin-bottom: 0; }
  .category-kim-jestesmy .cs-container.cs-container--image-teaser.cs-container--teaser-and-text .cs-image-teaser__content p.cs-image-teaser__slogan {
    font-size: 32px !important;
    font-weight: 700 !important; }
  .category-kim-jestesmy .cs-container.cs-container--image-teaser.cs-container--teaser-and-text .cs-image-teaser__slides.cs-image-teaser__slides.cs-image-teaser__slides--mobile_image-text {
    gap: 0 !important; }
  .category-kim-jestesmy .cs-container.cs-container--image-teaser.cs-container--teaser-and-text .cs-image-teaser__content .cs-image-teaser__cta span.cs-image-teaser__cta-button-span {
    font-size: 16px !important; }
  .category-kim-jestesmy .cs-container.cs-container--image-teaser.cs-container--teaser-and-text .cs-image-teaser__content .cs-image-teaser__cta span.cs-image-teaser__cta-span {
    font-size: 16px !important; }
