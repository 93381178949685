/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
 * Default project breakpoints - [em]
 */
.cs-footer-links {
  margin: 0;
  padding: 0;
  list-style: none; }
  .cs-footer-links__item {
    margin: 0 0 0.3em; }
    .cs-footer-links__item .am-attachments .am-fileline .am-filelink {
      line-height: inherit;
      height: inherit; }
  .cs-footer-links a, .cs-footer-links a:visited {
    color: #000;
    text-decoration: none; }
  .cs-footer-links a:hover, .cs-footer-links a:focus, .cs-footer-links a:active, .cs-footer-links a:visited:hover, .cs-footer-links a:visited:focus {
    color: #000;
    text-decoration: underline; }
  .cs-footer-links a, .cs-footer-links a:visited {
    display: block;
    padding: 0.5em 0;
    font-size: inherit; }
    @media (min-width: 48em) {
      .cs-footer-links a, .cs-footer-links a:visited {
        padding: 0.2em 0;
        display: inline-block; } }
