.page-layout-szachownica {
  .cs-image-teaser__description {
    font-size: 18px;
  }
  .column.main {
      width: auto;
      padding: 0rem 0 0;
      max-width: 100%;
      min-height: 10rem;
  }
}
